import React, {useState, useEffect} from 'react';
import { StyleSheet, Text, View, Image, Platform, BackHandler, ScrollView, TouchableOpacity, ActivityIndicator } from 'react-native';
import { useIsFocused, useFocusEffect} from '@react-navigation/native';
import { useSystemInfo, useAuth, useEnv } from '../AppContext';
import { PrintSystemData, RemoveSystemData, LoadFromSystem } from '../Global';
import GSS from '../GlobalStyleSheet2';
import { useModal } from '../ModalContext';
import CloseHeader from '../CloseHeader';
import nextImage from '../../assets/next.png';
import { HorizontalLine, appVersion } from '../Global';

function MyInfoHome ({navigation, route}) {
  const { systemInfo, setSystemInfo } = useSystemInfo();
  const { isLoggedIn, logout } = useAuth();
  const { ENV, setEnv } = useEnv();
  const isFocused = useIsFocused();
  const [isLogined, setIsLogined] = useState(false); 

  useFocusEffect(
    React.useCallback(() => {
        if (Platform.OS === 'web') {
            // 웹 플랫폼인 경우 이 코드 블록을 실행하지 않습니다.
            return;
        }
        const onBackPress = () => {
            navigation.reset({
                index: 0,
                routes: [{ name: 'home' }],
            });            
            return true;
        };
        BackHandler.addEventListener('hardwareBackPress', onBackPress);
        return () => {
            BackHandler.removeEventListener('hardwareBackPress', onBackPress);
        };
    }, [])
);    
  
  useEffect(() => {
    let isMounted = true; // 컴포넌트가 마운트 상태인지 추적합니다.

    // async 함수를 정의하고 즉시 호출합니다.
    (async () => {
      try {
        //const sysData = await LoadFromSystem();
        // 컴포넌트가 여전히 마운트 상태인 경우에만 상태를 설정하거나 로깅을 수행합니다.
        if (isMounted) {
          //console.log(sysData.kakao_id);
          if(ENV.user_id === null) {
            //console.log('로그아웃상태');
            LoginStep();
          }
        }
      } catch (e) {
        // 오류를 적절하게 핸들링합니다. (예: 로깅, 사용자에게 메시지 표시 등)
        console.error(e);
      }
    })();  
    // 컴포넌트 언마운트 시 isMounted를 false로 설정합니다.
    return () => {
      isMounted = false;
    };
  }, [isFocused, ENV.user_id]);
  
  function LoginStep()
  {
      ENV.target_after_login = 'MyInfoHome';
      const newENV = {...ENV};
      newENV.target_after_login = 'MyInfoHome';
      setEnv(newENV);
      if(Platform.OS == 'web')
      {
        navigation.navigate('LoginWeb');
      } else {
        navigation.navigate('Login');
      }
  }

  function LogoutStep()
  {
      const newENV = { ...ENV };
      newENV.user_id = null;
      newENV.last_login_type = null;
      newENV.checked_admin = null;
      setEnv(newENV);  
      navigation.reset({
        index: 0,
        routes: [{ name: 'home' }],
      });      
  } 

  const LastLoginType = () => {
    //console.log(ENV);
    //console.log(ENV.last_login_type);
    if(ENV.last_login_type) {
        if(ENV.last_login_type === "kakao") return <Text>카카오 로그인</Text>;
        else if(ENV.last_login_type === "naver") return <Text>네이버 로그인</Text>;
	      else if(ENV.last_login_type === "google") return <Text>구글 로그인</Text>;
        else if(ENV.last_login_type === "apple") return <Text>애플 로그인</Text>;
        else return <Text>{`${ENV.last_login_type} 로그인`}</Text>;
    } else {
        return null;
    }
  }

  if(isLoggedIn) {
    return (
      <View style={GSS.mainContainer}>
        <View style={GSS.mainViewBox}>
          <View style={{padding:5,}}>
              <Text style={[GSS.mainInfoTextB]}>마이샷 관리자 {appVersion} </Text>
          </View>  
        </View>
        <View style={[GSS.mainViewBox, {marginTop:25}]}>
          <View style={{ padding:5, alignItems:'center'}}>
            <TouchableOpacity onPress={LogoutStep} style={{flexDirection:'row', justifyContent: 'center',}}>
              <Text style={[GSS.textEmphasisAlert, {marginRight:5}]}>마이샷 로그아웃</Text><Text>({LastLoginType()} {ENV.user_id < 10 && ENV.user_id})</Text>
            </TouchableOpacity>
          </View>
        </View>     
      </View>
    );
  } else {
    return (
        <View style={GSS.mainContainer}>
            <View style={GSS.mainViewBox}>
                <Text>관리자 로그인을 하시오.</Text>
            </View>     
        </View>           
    )
  } 
};

export default MyInfoHome;