import React, {useRef, useEffect, useState} from 'react';
import { StyleSheet, Text, View, BackHandler , TouchableOpacity ,Linking, AppState, Platform} from 'react-native';
import { useIsFocused, useFocusEffect } from '@react-navigation/native';
import { useAuth, } from '../AppContext';
import { ScrollView } from 'react-native-gesture-handler';
import HomeOfLogin from './HomeOfLogin';
import HomeOfLogout from './HomeOfLogout';
import { showToast } from '../Global';
import { useModal } from '../ModalContext';
import { useNavigation } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import { LoadFromSystem } from '../Global';
import { useLoginProc } from '../LoginProc2';
import GSS from '../GlobalStyleSheet2'

const DOUBLE_PRESS_DELAY = 2000; 

function Home ({navigation, route}) {
  const { isLoggedIn } = useAuth();
  const lastBackPressed = useRef(Date.now());
  const { showModalMessage } = useModal();
  const navigation2 = useNavigation();
  const { loginProc } = useLoginProc();

  useFocusEffect(
    React.useCallback(() => {
      if (Platform.OS === 'web') {
        // 웹 플랫폼인 경우 이 코드 블록을 실행하지 않습니다.
        return;
      }       
      const onBackPress = () => {
        const timeNow = Date.now();
        if (timeNow - lastBackPressed.current < 2000) {
          BackHandler.exitApp(); // 앱 종료
          return true; // 이벤트 소비
        } else {
          lastBackPressed.current = timeNow;
          showToast('한 번 더 누르면 종료됩니다.');
          return true; // 이벤트 소비
        }
      };
      BackHandler.addEventListener('hardwareBackPress', onBackPress);
      return () => {
        BackHandler.removeEventListener('hardwareBackPress', onBackPress);
      };
    }, []) // 여기서는 의존성 배열을 비워둡니다.
  );

  const openURL = async (url) => {
    await WebBrowser.openBrowserAsync(url);
    //window.location.href = url;
  }; 
  
  const DisplayContent = () => {
    if(isLoggedIn) {
      return (
        <HomeOfLogin
          navigation={navigation}
        />
      );
    } else {
      return (
        <HomeOfLogout
        />
      );
    }    
  } 
  
  const makeCall = (phoneNumber) => {
    const url = `tel:${phoneNumber}`;
    Linking.canOpenURL(url)
      .then((supported) => {
        if (!supported) {
          console.log('Can\'t handle url: ' + url);
        } else {
          return Linking.openURL(url);
        }
      })
      .catch((err) => console.error('An error occurred', err));
  };  

  return (
    <ScrollView style={styles.container}>
      <DisplayContent/>
      <View style={{ justifyContent: 'center', backgroundColor: '#291B44', padding: 10}}>
        <View style={{marginTop: 25,}}>
          <Text style={styles.aboutTitle}>주식회사 웹프리즘</Text>
          <Text style={styles.aboutText}>대표 : 한지은</Text>
          <Text style={styles.aboutText}>주소 : 경기도 안양시 동안구 시민대로 230, 평촌아크로타워 B동 406-1호</Text>
          {/* <Text style={styles.aboutText}>통신판매번호 : </Text> */}
          <Text style={styles.aboutText}>사업자등록번호 : 439-81-00075</Text>
          <Text style={styles.aboutText}>법인등록번호 : 131111-0410024</Text>
          <Text style={styles.aboutText}>대표번호 :  031-478-9406</Text>
        </View>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },  
  aboutText: {
    color : '#8F8C83',
    marginBottom: 3
  },
  aboutTitle: {
    color : '#F7F7F7',
    fontWeight : 'bold',
    fontSize : 16,
    marginBottom: 15
  }
});


export default Home;