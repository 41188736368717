import React, {useEffect} from 'react';
import { Text, View, Image, ActivityIndicator } from 'react-native';
import GSS from './GlobalStyleSheet2';
import { useEnv } from './AppContext';
import { useFocusEffect } from '@react-navigation/native';
import { useLoginProc } from './LoginProc2';

export default function LoginRedirect({navigation, route}) {
    const { ENV, } = useEnv();
    let user_id = route.params?.user_id;
    let last_login_type = route.params?.last_login_type;
    const { loginProc } = useLoginProc();

    useFocusEffect (
      React.useCallback(() => {
        // 포커스될 때 실행할 코드
        //console.log('스크린이 포커싱되었습니다.');
        if(user_id && last_login_type) {
          //console.log(user_id, last_login_type, ENV.target_after_login);
          (async () => {
                try {
                    //console.log(ENV.target_after_login);
                    if( !ENV.target_after_login ) {
                        ENV.target_after_login = 'MyInfoHome';
                    }
                    const newENV = {};
                    newENV.user_id = user_id;
                    newENV.last_login_type = last_login_type;
                    //console.log(newENV);
                    await loginProc(newENV);
                    navigation.navigate(ENV.target_after_login);
                } catch (e) {
                    console.error(e);
                }
            })();          
        }        
        return () => {
          // 스크린이 포커스를 잃을 때 실행할 정리(clean-up) 코드
          //console.log('스크린이 포커스를 잃었습니다.');
          if(user_id) {
            user_id = null;
            last_login_type = null;
          }
        };
      }, [])
    );

    return (
      <View style={GSS.mainContainer}>
        {
            user_id && (
                <View style={{alignItems: 'center', justifyContent:'center'}}>
                    <Text style={GSS.mainDefaultText}>로그인되었습니다</Text>
                    <View style={GSS.activityIndicatorContainer}>
                        <ActivityIndicator size='large' color="#0000ff" />
                    </View>                    
                </View>    
            )
        }        
      </View>
    );
}
