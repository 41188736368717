import React, {useState, useEffect} from 'react';
import { Text, View, Image, Platform, BackHandler, ScrollView, TouchableOpacity, ActivityIndicator } from 'react-native';
import { useFocusEffect} from '@react-navigation/native';
import { useSystemInfo, useAuth, useEnv } from '../AppContext';
import GSS from '../GlobalStyleSheet2';
import { useModal } from '../ModalContext';
import TopHeader from '../TopHeader';
import { GetRow, Refund, GetRefundInfo, DeleteUsedItemToDB } from '../api';
import * as WebBrowser from 'expo-web-browser';
import LinkImage from '../../assets/share.png';
import { formatDate } from '../Global';
import AutoPay from './AutoPay';

function PayResult ({navigation, route}) {
    const [isLoading, setIsLoading] = useState(false);
    const { auto_pay, SetRefreshOK } = route.params;
    const [ payResult, setPayResult ] =useState(null);
    const [ payHistory, setPayHistory] = useState(null);
    const { getItemById } = useEnv();
    const { showModalMessage } = useModal();
    const { isLoggedIn } = useAuth();
    const [ refundInfo, SetRefundInfo] = useState();

    useFocusEffect(
        React.useCallback(() => {
            if(isLoggedIn!==true) {
                navigation.reset({
                    index: 0,
                    routes: [{ name: 'home' }],
                });                
            }
        }, [isLoggedIn]) // 여기서는 의존성 배열을 비워둡니다.
    );      

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            try {
                console.log(auto_pay);
                const sql = 'select * from t_pay_history where history_id=?';
                const params = [auto_pay.last_history_id];
                const ret = await GetRow(sql, params);
                if( ret.success && ret.data!==null ) {
                    //console.log(ret.data);
                    const obj = JSON.parse(ret.data.pay_log);  
                    //console.log(obj);
                    setPayResult(obj);
                    const history = {
                        refund_cost : ret.data.refund_cost,
                        pay_cost : ret.data.pay_cost,
                        pay_result : ret.data.pay_result,
                        pay_time : formatDate(ret.data.create_at),
                        pay_date : ret.data.pay_date
                    };
                    //console.log(history);
                    const userResult = await GetRow('select user_name,phone_number from t_user where user_id=?', [auto_pay.user_id]);
                    if( userResult.success) {
                        history.user_name = userResult.data.user_name;
                        history.phone_number = userResult.data.phone_number;
                    }
                    setPayHistory(history);

                    const refund = await GetRefundInfo(auto_pay.used_item_id);
                    //console.log(refund);
                    if( refund.success) {
                        //console.log(refund.data);
                        SetRefundInfo(refund.data);
                    }

                } else {

                }
            } catch (e) {
                console.error(e);
            }
            setIsLoading(false);
        })();
    
    }, [auto_pay]);    

    async function goUrl(url) {
        await WebBrowser.openBrowserAsync(url);
    }     
 
    async function refundProc() {
        const _message = {
            body : ''
        }
        setIsLoading(true);
        try {
            const result = await DeleteUsedItemToDB(auto_pay.used_item_id);
            if( result.success ) {
                _message.body = result.data;
                SetRefreshOK(true);
                navigation.goBack();              
            } else {
                _message.body = result.error;
            }
        } catch(error) {
            //console.log(error);
            _message.body = error._message;
        }
        setIsLoading(false);
        showModalMessage(_message);
    }      

    return (
        <ScrollView style={{flex:1}}>
        <View style={GSS.mainContainer}>
            <View style={{height:40}}>
                <TopHeader 
                    navigation={navigation}
                >상세 결제 내용</TopHeader>
            </View>
            <View style={[GSS.mainViewBox,]}>
                <View style={{flexDirection:'row'}}>
                    <View style={{flex:1, margin:5}}>
                        <View style={{flexDirection:'row'}}>
                            <Text style={[GSS.mainDefaultText, {width:85}]}>결제금액</Text>
                            <Text style={[GSS.mainDefaultText, {flex:1}]}>{refundInfo?.payPrice.toLocaleString()}</Text>
                        </View>
                        <View style={{flexDirection:'row'}}>
                            <Text style={[GSS.mainDefaultText, {width:85}]}>구독일</Text>
                            <Text style={[GSS.mainDefaultText, {flex:1}]}>{refundInfo?.refundDate}</Text>
                        </View>
                        <View style={{flexDirection:'row'}}>
                            <Text style={[GSS.mainDefaultText, {width:85}]}>환불금액</Text>
                            <Text style={[GSS.mainDefaultText, {flex:1}]}>{refundInfo?.refundCost.toLocaleString()}</Text>
                        </View>                        
                    </View>
                    <View style={{flex:0.4, alignItems:'center', justifyContent:'center'}}>
                        { payHistory?.pay_cost ? (
                            <TouchableOpacity
                                onPress={refundProc}
                            >
                                <Text style={GSS.labelContainer}>환불처리</Text>
                            </TouchableOpacity>
                        ) : (
                            <Text style={GSS.mainDisableText}>환불처리</Text>
                        )}
                    </View>
                </View>
             </View>
            {isLoading && (
                <View style={GSS.activityIndicatorContainer}>
                    <ActivityIndicator color="#0000ff" />
                </View>
            )}               
            <View style={[GSS.mainViewBox, {flex:1,}]}>
                <View style={[GSS.mainViewBox, {backgroundColor:'#f8f8f8'}]}>   
                    <View style={{flexDirection:'row'}}>
                        <Text style={[GSS.mainDefaultText, {width:85}]}>마이샷 ID</Text>
                        <Text style={[GSS.mainDefaultText, {flex:1}]}>{`${auto_pay.user_id} [${payHistory?.user_name}][${payHistory?.phone_number}]`}</Text>
                    </View>
                    <View style={{flexDirection:'row'}}>
                        <Text style={[GSS.mainDefaultText, {width:85}]}>아이템</Text>
                        <Text style={[GSS.mainDefaultText, {flex:1}]}>{getItemById(auto_pay.item_id)?.item_name}</Text>
                    </View> 
                    <View style={{flexDirection:'row'}}>
                        <Text style={[GSS.mainDefaultText, {width:85}]}>파티 ID</Text>
                        <Text style={[GSS.mainDefaultText, {flex:1}]}>{auto_pay.user_item_id}</Text>
                    </View>  
                    { payHistory !== null && (
                        <View>
                            <View style={{flexDirection:'row'}}>
                                <Text style={[GSS.mainDefaultText, {width:85}]}>결제날짜</Text>
                                <Text style={[GSS.mainDefaultText, {flex:1}]}>{payHistory.pay_time}</Text>
                            </View> 
                        </View>
                    )} 
                </View>
                { payResult !== null && (
                <View style={[GSS.mainViewBox, {backgroundColor:'#f8f8f8', marginTop:10}]}>
                    <View style={{flexDirection:'row'}}>
                        <Text style={[GSS.mainDefaultText, {width:85}]}>결제 ID</Text>
                        <Text style={[GSS.mainDefaultText, {flex:1}]}>{payResult.PCD_PAYER_ID}</Text>
                    </View>                
                    <View style={{flexDirection:'row'}}>
                        <Text style={[GSS.mainDefaultText, {width:85}]}>결제요청결과</Text>
                        <Text style={[GSS.mainDefaultText, {flex:1}]}>{payResult.PCD_PAY_CODE}</Text>
                    </View>                
                    <View style={{flexDirection:'row'}}>
                        <Text style={[GSS.mainDefaultText, {width:85}]}>결제결과</Text>
                        <Text style={[GSS.mainDefaultText, {flex:1}]}>{payResult.PCD_PAY_MSG}</Text>
                    </View>
                    <View style={{flexDirection:'row'}}>
                        <Text style={[GSS.mainDefaultText, {width:85}]}>회원 ID</Text>
                        <Text style={[GSS.mainDefaultText, {flex:1}]}>{payResult.PCD_PAYER_NO}</Text>
                    </View>                
                    <View style={{flexDirection:'row'}}>
                        <Text style={[GSS.mainDefaultText, {width:85}]}>상품명</Text>
                        <Text style={[GSS.mainDefaultText, {flex:1}]}>{payResult.PCD_PAY_GOODS}</Text>
                    </View>                
                    <View style={{flexDirection:'row'}}>
                        <Text style={[GSS.mainDefaultText, {width:85}]}>결제금액</Text>
                        <Text style={[GSS.mainDefaultText, {flex:1}]}>{payResult.PCD_PAY_TOTAL.toLocaleString()}</Text>
                    </View> 
                    <View style={{flexDirection:'row'}}>
                        <Text style={[GSS.mainDefaultText, {width:85}]}>부가세</Text>
                        <Text style={[GSS.mainDefaultText, {flex:1}]}>{payResult.PCD_PAY_TAXTOTAL}</Text>
                    </View> 
                    <View style={{flexDirection:'row'}}>
                        <Text style={[GSS.mainDefaultText, {width:85}]}>카드사명</Text>
                        <Text style={[GSS.mainDefaultText, {flex:1}]}>{payResult.PCD_PAY_CARDNAME}</Text>
                    </View>                
                    <View style={{flexDirection:'row'}}>
                        <Text style={[GSS.mainDefaultText, {width:85}]}>카드번호</Text>
                        <Text style={[GSS.mainDefaultText, {flex:1}]}>{payResult.PCD_PAY_CARDNUM}</Text>
                    </View> 
                    {/* <View style={{flexDirection:'row'}}>
                        <Text style={[GSS.mainDefaultText, {width:85}]}>결제시간</Text>
                        <Text style={[GSS.mainDefaultText, {flex:1}]}>{payResult.PCD_PAY_TIME}</Text>
                    </View>  */}
                    <View style={{flexDirection:'row'}}>
                        <Text style={[GSS.mainDefaultText, {width:85}]}>카드거래번호</Text>
                        <Text style={[GSS.mainDefaultText, {flex:1}]}>{payResult.PCD_PAY_CARDTRADENUM}</Text>
                    </View>                
                    <View style={{flexDirection:'row'}}>
                        <Text style={[GSS.mainDefaultText, {width:85}]}>카드승인번호</Text>
                        <Text style={[GSS.mainDefaultText, {flex:1}]}>{payResult.PCD_PAY_CARDAUTHNO}</Text>
                    </View>  
                    <View style={{flexDirection:'row'}}>
                        <Text style={[GSS.mainDefaultText, {width:85}]}>카드매출전표</Text>
                        <Text style={[GSS.mainDefaultText, {flex:1}]}>매출전표보기</Text>
                        <TouchableOpacity
                          onPress={() => {goUrl(payResult.PCD_PAY_CARDRECEIPT)}}
                        >
                            <Image
                                source={LinkImage}
                                style={{ marginLeft:10, width: 20, height: 20 }}             
                            />
                        </TouchableOpacity>
                    </View>                                                                                                 
                </View> 
                )}
          </View>
        </View>
        </ScrollView>
    );
};

export default PayResult;