import React, { useState, useCallback } from 'react';
import { TouchableOpacity, Text, StyleSheet } from 'react-native';

const ProgressButton = React.memo(({ onPress, title, style, textStyle, color = '#291B44', disabled=false }) => {
  const [containerWidth, setContainerWidth] = useState(null);

  const handleTextLayout = useCallback((event) => {
    const { width } = event.nativeEvent.layout;
    if (containerWidth !== width) {
      setContainerWidth(width);
    }
  }, [containerWidth]); // 의존성 배열에 containerWidth를 포함시킵니다.
  
  return (
    <TouchableOpacity
      style={[styles.button, 
        { backgroundColor: disabled ? '#e1e3e6' : color }, 
        { width: containerWidth ? containerWidth + 75 : 'auto' },
        style]} // 기본 스타일, 배경색, 그리고 커스텀 스타일을 함께 적용합니다.
      onPress={disabled ? null : onPress}
      disabled={disabled}
    >
      <Text style={[styles.text, textStyle]} onLayout={handleTextLayout}>{title}</Text>
    </TouchableOpacity>
  );
});

export const ProgressButtonNoWidth = React.memo(({ onPress, title, style, textStyle, color = '#291B44' }) => {
  return (
    <TouchableOpacity
      style={[styles.button, 
        { flex: 1, backgroundColor: color }, 
        style]} // 기본 스타일, 배경색, 그리고 커스텀 스타일을 함께 적용합니다.
      onPress={onPress}
    >
      <Text style={[styles.text, textStyle]}>{title}</Text>
    </TouchableOpacity>
  );
});


export const ProgressButtonOrg = ({onPress, title, style, textStyle, color = '#291B44'}) => {
  return (
    <TouchableOpacity
      style={[styles.button, 
        { backgroundColor: color }, 
        style]} // 기본 스타일, 배경색, 그리고 커스텀 스타일을 함께 적용합니다.
      onPress={onPress}
    >
      <Text style={[styles.text, textStyle]}>{title}</Text>
    </TouchableOpacity>
  );
};

export const ProgressNext = ({title, onPress, disabled=false, width=150}) => {
  return (
    <ProgressButtonOrg
      title={title}
      onPress={onPress}
      style={{borderWidth:2, borderColor: disabled ? '#f1f3f6' : '#00e5e0', width: width}}
      textStyle={{color: disabled ? '#e2e0e5' : '#291B44'}}
      color='white'
    />
  );
};

const styles = StyleSheet.create({
  button: {
    padding: 10,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 5, // 버튼 간 간격을 주기 위함입니다.
  },
  text: {
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
});

export default ProgressButton;