import React, { useEffect, useState, useRef  } from 'react';
import { Text, View,  TouchableOpacity, StyleSheet, Image, Dimensions } from 'react-native';
import GSS from '../GlobalStyleSheet2';
import TopHeader from '../TopHeader';
import { useModal } from '../ModalContext';
import * as Clipboard from 'expo-clipboard';
import ConfirmImage from '../ConfirmImage';

export default function verify19({navigation, route}) {
    const { emailAccount } = route.params;
    const newWindowRef = useRef(null);
    const [ checkLogin, setCheckLogin] = useState(false);
    const [ loginCompleted, setLoginCompleted] = useState(false);
    const { showMessage, showModalMessage } = useModal();

    const loginProc = () => {
        if (!newWindowRef.current) {
            newWindowRef.current = window.open('http://www.netflix.com/login', '_blank');
        }        
        setCheckLogin(true);
    }

    const changeWindowUrl = (newUrl) => {
        if (newWindowRef.current) {
            newWindowRef.current.location.href = newUrl;
        }
    };  
    
    const copyToClipboard = async (text, isMessage=true) => {
        await Clipboard.setStringAsync(text);
        if( isMessage ) {
            showMessage('클립보드에 복사되었습니다.');
        }
    };

    const afterLoginCompleted = () => {
        //changeWindowUrl('https://www.netflix.com/account/profiles');
        changeWindowUrl('https://www.netflix.com/verifyage');
        setLoginCompleted(true);
    }

    const windowWidth = Dimensions.get('window').width;
    return (
        <View style={[GSS.mainContainer]}>
            <View style={{height:40}}>
                <TopHeader 
                    navigation={navigation}
                >프로필 성인 인증</TopHeader>
            </View> 
            <View style={[GSS.mainViewBox, {flex:1}]}>
                <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                    <Text style={[GSS.mainInfoText, {width:40}]}>계정</Text>
                    <Text style={[GSS.mainInfoTextB, {flex:1}]}>{emailAccount?.email}</Text>
                    <TouchableOpacity onPress={() => copyToClipboard(emailAccount?.email)}>
                        <Text style={[GSS.mainDefaultText, {marginRight:10}]}>복사</Text>
                    </TouchableOpacity>
                </View>
                <View style={{flexDirection:'row', justifyContent:'space-between', marginTop:10}}>
                    <Text style={[GSS.mainInfoText, {width:40}]}>비번</Text>
                    <Text style={[GSS.mainInfoTextB, {flex:1}]}>{emailAccount?.pwd}</Text>
                    <TouchableOpacity onPress={() => copyToClipboard(emailAccount?.pwd)}>
                        <Text style={[GSS.mainDefaultText, {marginRight:10}]}>복사</Text>
                    </TouchableOpacity>
                </View>     
                <View style={[GSS.mainViewBox, {marginTop: 25}]}>
                    <View style={SS.confirmView}>
                        <Text style={[GSS.mainInfoText, {margin:3, width:windowWidth*0.8, }]}>위 계정으로 넷플릭스에 로그인 하세요</Text>
                        <ConfirmImage
                            isActive={checkLogin}
                            onPress={loginProc}
                        />                        
                    </View>
                </View>
                {checkLogin && (
                    <View style={GSS.mainViewBox}>
                        <View>
                            <Text style={GSS.mainDefaultText}>위 계정과 비번을 복사하여 넷플릭스에 로그인 후 확인을 진행해 주세요</Text>
                        </View>
                        <View style={SS.confirmView}>
                            <Text style={[GSS.mainInfoText, {margin:3, width:windowWidth*0.8, }]}>로그인 되었나요?</Text>
                            <ConfirmImage
                                isActive={loginCompleted}
                                onPress={afterLoginCompleted}
                            />                        
                        </View>
                    </View>                      
                )}
                { loginCompleted && (
                    <View style={GSS.mainViewBox}>
                        <View>
                            <Text style={GSS.mainDefaultText}>내 프로필을 선택 후 시청 제한 메뉴를 선택해 진행해 주세요</Text>
                        </View>

                    </View>                     
                )}
            </View>
        </View>        
    );
}

const SS = StyleSheet.create({
    confirmView: {
        //flex: 
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems : 'center',
    },     
});