import React, {useState, useEffect} from 'react';
import { Text, View, TouchableOpacity , Image, StyleSheet, ActivityIndicator, FlatList , Pressable} from 'react-native';
import { useAuth, useEnv, GetOTTByName} from '../AppContext';
import GSS from '../GlobalStyleSheet2'
import NextImage from '../../assets/next.png';
import { ExecuteSql, GetRows, GetRow} from '../api';
import TopHeader from '../TopHeader';
import { useFocusEffect } from '@react-navigation/native';
import CheckBox from 'expo-checkbox';
import { formatDate, HorizontalLine, truncateString } from '../Global';
import refreshImage from '../../assets/refresh.png';

function UserInfo ({navigation, route}) {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMoreData, setHasMoreData] = useState(true);
    const { isLoggedIn } = useAuth();
    const [selectedItem, setSelectedItem] = useState(null);
    const [ refreshOK, SetRefreshOK] = useState(false);
    const target_user_id = route.params?.target_user_id ?? null;

    useFocusEffect(
        React.useCallback(() => {
            if(isLoggedIn!==true) {
                navigation.reset({
                    index: 0,
                    routes: [{ name: 'home' }],
                });                
            }
        }, [isLoggedIn]) // 여기서는 의존성 배열을 비워둡니다.
    );      

    const fetchData = async () => {
        if (isLoading || !hasMoreData) return;
        setIsLoading(true);
        try {
            //console.log(`Before fetching data, page: ${page}`);
            const itemsPerPage = 25; // 한 페이지당 아이템 수
            // 페이지가 1부터 시작한다고 가정할 때 올바른 offset 계산
            const offset = (page - 1) * itemsPerPage; 
            const where = target_user_id === null ? '' : `WHERE user_id=${target_user_id}`;
            const sql = `
                SELECT u.user_id, u.phone_number, u.user_name, u.last_login_type, u.create_at,
                    u.checked_mkt, u.checked_certification, u.is_active,u.device_info,
                    (SELECT COUNT(*) FROM t_user_item ui WHERE ui.user_id = u.user_id) AS user_item_cnt,
                    (SELECT COUNT(*) FROM t_used_item ui WHERE ui.user_id = u.user_id) AS used_item_cnt
                FROM t_user u
                ${where}
                ORDER BY u.create_at DESC
                LIMIT ? OFFSET ?;
            `;
            const ret = await GetRows(sql, [itemsPerPage, offset]);
            if (ret.success) {
                const newData = ret.data;
                if (newData.length > 0) {
                    setData(prevData => [...prevData, ...newData]);
                    setPage(prevPage => prevPage + 1);
                } else {
                    setHasMoreData(false);
                }
            }
            //console.log(`After fetching data, new page: ${page + 1}`);
        } catch (e) {
            console.error(e);
        }
        setIsLoading(false);
    };
    
    useEffect(() => {
        fetchData();
    }, []);

    const loadMoreData = () => {
        fetchData();
    };

    useEffect(() => {
        if(refreshOK) {
            fetchData();
            SetRefreshOK(false);
        }
    }, [refreshOK]);      

    const refreshProc = () => {
        setData([]); // 데이터 배열을 비웁니다.
        setPage(1); // 페이지 번호를 초기화합니다.
        setHasMoreData(true); // 더 많은 데이터가 있다고 가정합니다.
        setSelectedItem(false);
        //await fetchData(); // 데이터를 다시 불러옵니다.
        SetRefreshOK(true);
    };   

    const handleSelectItem = async (item) => {
        try {
            // if( item.user_id ) {
            //     const ret = await GetRow('SELECT * FROM t_user WHERE user_id=?', [item.user_id]);
            //     if( ret.success) {
            //         setEmailAccount( ret.data);
            //     }
            // }
            if( selectedItem === null || selectedItem.user_id !== item.user_id) {
                setSelectedItem(item);
            }
            // setSelectedItem(selectedItem === item ? null : item);
        } catch (error) {
            console.log(error);
        }
    };  

    async function DeleteUser() {

    }

    async function SetMarketer(user_id, checked_mkt) {
        try {
            const action = checked_mkt === 1 ? false : true;
            const ret = await ExecuteSql(`update t_user set checked_mkt=${action} where user_id=?`, [user_id]);
            if( ret.success) {
                refreshProc();
            }
        } catch(e) {
            console.log(e);
        }
    }

    const displayUserItem = (user_id) => {
        navigation.navigate('UserItemInfo', { target_user_id: user_id });
    }

    const displayUsedItem = (user_id) => {
        navigation.navigate('UsedItemInfo', { target_user_id: user_id });
    }    
    
    function RenderSelectedItem ({item}) {
        if(!selectedItem || !item) return null;
        //console.log(item);
        return (
            <View style={[GSS.mainViewBox, {borderWidth:1}]}>
                <View style={{flexDirection: 'row', }}>
                    <View style={{flex: 0.3}}>
                        <Text style={GSS.mainDefaultTextB}>{item.user_id}</Text>
                    </View>
                    <View style={{flex: 0.4}}>
                        <Text style={GSS.mainDefaultText}>{item.user_name}</Text>
                    </View>
                    <View style={{flex: 0.3}}>
                        <Text style={GSS.mainDefaultText}>{item.phone_number}</Text>
                    </View>                                         
                    <View style={{flex: 1}}>
                        <Text style={GSS.mainDefaultText}>{formatDate(item.create_at)}</Text>
                    </View>
                    {/* <View style={{flex: 1}}>
                        <Text style={GSS.mainDefaultText}>{formatDate(item.request_at)}</Text>
                    </View>                 */}
                </View>
                <HorizontalLine
                    thickness={0.5}
                />
                <View style={{flexDirection: 'row', }}>
                    <Text style={GSS.mainDefaultText}>{item.device_info}</Text>
                </View>
                <HorizontalLine
                    thickness={0.5}
                />
                <View style={{flexDirection: 'row', justifyContent:'space-evenly', alignItems:'center' }}>
                    { item.user_item_cnt > 0 && (
                        <TouchableOpacity
                            onPress={() => displayUserItem(item.user_id)}
                        >
                            <Text style={GSS.labelContainer}>{` 파티정보보기 `}</Text>
                        </TouchableOpacity>                       
                    )}
                    { item.used_item_cnt > 0 && (
                        <TouchableOpacity
                            onPress={() => displayUsedItem(item.user_id)}
                        >
                            <Text style={GSS.labelContainer}>{` 파티원정보보기 `}</Text>
                        </TouchableOpacity>                       
                    )}                   
                    { item.checked_certification === 1 && (
                        <TouchableOpacity
                            onPress={() => {SetMarketer(item.user_id, item.checked_mkt)}}
                        >
                            <Text style={GSS.labelContainer}>{item.checked_mkt === 1 ? `  마케터 해제  ` : `  마케터 지정  `}</Text>
                        </TouchableOpacity> 
                    )}
                    <TouchableOpacity
                        onPress={DeleteUser}
                    >
                        <Text style={GSS.labelContainer}>{`  회원 삭제  `}</Text>
                    </TouchableOpacity>
                </View>
            </View>
        );
    }      
    
    const renderItem = ({ item }) => {
        return (
            <View style={[
                { flexDirection: 'row' },
                selectedItem?.user_id === item.user_id ? SS.selected : null
              ]}>
                <CheckBox
                    value={selectedItem?.user_id===item.user_id}
                    onValueChange={() => handleSelectItem(item)}
                />             
                <View style={{flex: 0.3, marginLeft:2}}>
                    <Text style={item.is_active === 1 ? (GSS.mainInfoTextB) : GSS.mainDefaultText}>{item.user_id}</Text>
                </View>
                <View style={{flex: 0.5}}>
                   <Text style={GSS.mainDefaultText}>{item.user_name}<Text style={GSS.textEmphasis}>{item.checked_mkt === 1 && ' (마)'}</Text></Text>
                </View>
                <View style={{flex: 0.3, marginLeft:2}}>
                    { item.user_item_cnt > 0 && (
                        <TouchableOpacity
                            onPress={() => displayUserItem(item.user_id)}
                        >
                            <Text style={GSS.textEmphasis}>{item.user_item_cnt}</Text>
                        </TouchableOpacity>
                    )}
                </View>        
                <View style={{flex: 0.3, marginLeft:2}}>
                    { item.used_item_cnt > 0 && (
                        <TouchableOpacity
                            onPress={() => displayUsedItem(item.user_id)}
                        >               
                            <Text style={GSS.emphasisText}>{item.used_item_cnt}</Text>
                        </TouchableOpacity>
                    )}
                </View>                   
                <View style={{flex: 0.3}}>
                  <Text style={GSS.mainDefaultText}>{item.last_login_type}</Text>
                </View>
                <View style={{flex: 0.5}}>
                  <Text style={GSS.mainDefaultTextB}>{item.phone_number}</Text>
                </View>               
                <View style={{flex: 1.25}}>
                    <Text style={GSS.mainDefaultText}>{truncateString( item.device_info, 25)}</Text>
                </View>
            </View>
        );
    }; 

    return (
        <View style={[GSS.mainContainer]}>
            <View style={{height:40}}>
                <TopHeader 
                    navigation={navigation}
                    endImageLink={{
                        image : refreshImage,
                        goLink : refreshProc
                    }}
                >회원 정보</TopHeader>
            </View> 
            { selectedItem && (
                <RenderSelectedItem
                    item={selectedItem}
                />
            )}    
            {hasMoreData && (
                <Pressable
                    onPress={fetchData}
                    style={({ pressed }) => [
                        {
                            backgroundColor: pressed ? 'rgb(210, 230, 255)' : 'white'
                        },
                        SS.button
                    ]}
                    >
                    {({ pressed }) => (
                        <Text style={SS.text}>
                            {pressed ? '...' : '더보기'}
                        </Text>
                    )}
                </Pressable>
            )}                    
            <View style={[GSS.mainViewBox, {flex:1,}]}>
                <FlatList
                    data={data}
                    renderItem={renderItem}
                    keyExtractor={item => item.user_id}
                    //onEndReached={loadMoreData}
                    onEndReachedThreshold={0.2} // 리스트의 20% 지점에서 새 데이터 로드
                    ListFooterComponent={isLoading ? <ActivityIndicator /> : null}
                />
            </View>
        </View>
    );
};

const SS = StyleSheet.create({
    button: {
      // 버튼의 기본 스타일을 정의합니다.
      padding: 10,
      backgroundColor: '#841584',
      borderRadius: 5,
    },
    text: {
      // 텍스트의 스타일을 정의합니다.
      color: 'white',
      textAlign: 'center',
    },
    selected : {
        borderBottomWidth : 0.5
    }
});

export default UserInfo;