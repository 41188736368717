import React, {useState, useEffect} from 'react';
import { StyleSheet, Text, View, Image, Platform, BackHandler, ScrollView, TouchableOpacity, ActivityIndicator } from 'react-native';
import { useIsFocused, useFocusEffect} from '@react-navigation/native';
import { useSystemInfo, useAuth, useEnv } from '../AppContext';
import { PrintSystemData, RemoveSystemData, LoadFromSystem } from '../Global';
import GSS from '../GlobalStyleSheet2';
import { useModal } from '../ModalContext';
import CloseHeader from '../CloseHeader';
import nextImage from '../../assets/next.png';
import { HorizontalLine, appVersion } from '../Global';

function PayHome ({navigation, route}) {
    const { systemInfo, setSystemInfo } = useSystemInfo();
    const { isLoggedIn, logout } = useAuth();
    const { ENV, setEnv } = useEnv();
    const isFocused = useIsFocused();

    useFocusEffect(
        React.useCallback(() => {
            if (Platform.OS === 'web') {
                // 웹 플랫폼인 경우 이 코드 블록을 실행하지 않습니다.
                return;
            }
            const onBackPress = () => {
                navigation.reset({
                    index: 0,
                    routes: [{ name: 'home' }],
                });            
                return true;
            };
            BackHandler.addEventListener('hardwareBackPress', onBackPress);
            return () => {
                BackHandler.removeEventListener('hardwareBackPress', onBackPress);
            };
        }, [])
    );   

    const goPayInfo = () => {
        navigation.navigate('AutoPay');
    }   

    const goRefundInfo = () => {
        navigation.navigate('PayInfo');
    }       
    
    if(isLoggedIn) {
        return (
            <View style={[GSS.mainContainer]}>
                <View style={[GSS.mainViewBox, ]}>
                    <TouchableOpacity style={{flexDirection:'row', justifyContent:'space-between'}} onPress={goPayInfo}>
                        <Text style={GSS.mainInfoText}>자동 결제 정보</Text>
                        <Image
                            source={nextImage}
                            style={{height:25, width:25}}
                        />               
                    </TouchableOpacity>
                </View>   
                <View style={[GSS.mainViewBox,]}>
                    <TouchableOpacity style={{flexDirection:'row', justifyContent:'space-between'}}>
                        <Text style={GSS.mainInfoText}>결제 정보</Text>
                        <Image
                            source={nextImage}
                            style={{height:25, width:25}}
                        />               
                    </TouchableOpacity>
                </View>                
                <View style={[GSS.mainViewBox,]}>
                    <TouchableOpacity style={{flexDirection:'row', justifyContent:'space-between'}}>
                        <Text style={GSS.mainInfoText}>환불 정보</Text>
                        <Image
                            source={nextImage}
                            style={{height:25, width:25}}
                        />               
                    </TouchableOpacity>
                </View>                             
            </View>
        );
    } else {
        return (
            <View style={GSS.mainContainer}>
                <View style={GSS.mainViewBox}>
                    <Text>관리자 로그인을 하시오.</Text>
                </View>     
            </View>           
        )
    }
};

export default PayHome;