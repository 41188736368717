import React, {useState, useEffect} from 'react';
import { Text, View, TouchableOpacity, Image, Dimensions, ActivityIndicator } from 'react-native';
import { useAuth, useEnv, GetOTTByName} from '../AppContext';
import GSS from '../GlobalStyleSheet2'
import NextImage from '../../assets/next.png';
import { GetRow } from '../api';
import refreshImage from '../../assets/refresh.png';

function HomeOfLogin ({navigation, route}) {
    const { height, width: screenWidth } = Dimensions.get('window');
    const [isLoading, setIsLoading] = useState(false);
    const [ userTotal, setUserTotal] = useState();
    const [ userItemTotal, setUserItemTotal] = useState();
    const [ usedItemTotal, setUsedItemTotal] = useState();
    const [ waiterTotal, setWaiterTotal] = useState();
    const [ userItemFreeTotal, setUserItemFreeTotal] = useState();
    const [ usedItemFreeTotal, setUsedItemFreeTotal] = useState();    

    // useEffect(() => {
    //     (async () => {
    //         try {
    //         } catch (e) {
    //             console.error(e);
    //         }
    //     })();
    
    // }, []);   

    const checkStatus = async () => {
        setIsLoading(true);
        try {
            let sql = 'select count(*) as cnt from t_user';
            let ret = await GetRow(sql);
            if(ret.success) {
                //console.log(ret.data);
                setUserTotal(ret.data.cnt);
            }
            sql = `
            SELECT
                SUM(CASE WHEN free_charge = true THEN 1 ELSE 0 END) AS free_cnt,
                SUM(CASE WHEN free_charge = false THEN 1 ELSE 0 END) AS cnt
            FROM t_user_item;
            `;
            ret = await GetRow(sql);
            if(ret.success) {
                //console.log(ret.data);
                setUserItemTotal(ret.data.cnt);
                setUserItemFreeTotal(ret.data.free_cnt);
            }
            sql = `
            SELECT
                SUM(CASE WHEN free_charge = true THEN 1 ELSE 0 END) AS free_cnt,
                SUM(CASE WHEN free_charge = false THEN 1 ELSE 0 END) AS cnt
            FROM t_used_item;           
            `;
            ret = await GetRow(sql);
            if(ret.success) {
                //console.log(ret.data);
                setUsedItemTotal(ret.data.cnt);
                setUsedItemFreeTotal(ret.data.free_cnt);
            } 
            sql = 'select count(*) as cnt from t_waiting_list';
            ret = await GetRow(sql);
            if(ret.success) {
                //console.log(ret.data);
                setWaiterTotal(ret.data.cnt);
            }                                               
        } catch (e) {
            console.error(e);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        (async () => {
            await checkStatus();
        })();
    
    }, []);      

    const Proc1 = () => {
    }

    const goUserInfo = () => {
        navigation.navigate('UserInfo');
    }

    const goUserItemInfo = () => {
        navigation.navigate('UserItemInfo');
    }

    const goUsedItemInfo = () => {
        navigation.navigate('UsedItemInfo');
    }

    const goWaiterInfo = () => {
        navigation.navigate('WaiterInfo');
    }

    const refresh = async () => {
        await checkStatus();
    }

    return (
        <View style={[GSS.mainContainer, {height: height}]}>
            <View style={[GSS.emptyViewBox, {height:50, flexDirection:'row', justifyContent:'space-between', alignItems:'center'}]}>
                <Text style={GSS.mainBigText}>마이샷 관리자</Text>
                <TouchableOpacity
                    onPress={refresh}
                >
                    <Image source={refreshImage} style={{height:25, width:25}}/>
                </TouchableOpacity>
            </View>         
            {isLoading && (
                <View style={GSS.activityIndicatorContainer}>
                    <ActivityIndicator size='large' color="#0000ff" />
                </View>
            )}           
            <View style={[GSS.mainViewBox]}>
                <Text style={GSS.mainDefaultText}>현재 회원 : {userTotal}</Text>
                <Text style={GSS.mainDefaultText}>현재 파티 : {userItemTotal} , 무료 파티 : {userItemFreeTotal}</Text>
                <Text style={GSS.mainDefaultText}>현재 파티원 : {usedItemTotal} , 무료 파티원 : {usedItemFreeTotal}</Text>
                <Text style={GSS.mainDefaultText}>현재 대기 : {waiterTotal}</Text>
            </View>
            <View style={[GSS.mainViewBox, ]}>
                <TouchableOpacity style={{flexDirection:'row', justifyContent:'space-between'}} onPress={goUserInfo}>
                    <Text style={GSS.mainInfoText}>회원 정보</Text>
                    <Image
                        source={NextImage}
                        style={{height:25, width:25}}
                    />               
                </TouchableOpacity>
            </View>
            <View style={[GSS.mainViewBox,]}>
                <TouchableOpacity style={{flexDirection:'row', justifyContent:'space-between'}} onPress={goUserItemInfo}>
                    <Text style={GSS.mainInfoText}>파티 정보</Text>
                    <Image
                        source={NextImage}
                        style={{height:25, width:25}}
                    />               
                </TouchableOpacity>
            </View> 
            <View style={[GSS.mainViewBox, ]}>
                <TouchableOpacity style={{flexDirection:'row', justifyContent:'space-between'}} onPress={goUsedItemInfo}>
                    <Text style={GSS.mainInfoText}>파티원 정보</Text>
                    <Image
                        source={NextImage}
                        style={{height:25, width:25}}
                    />               
                </TouchableOpacity>
            </View> 
            <View style={[GSS.mainViewBox, ]}>
                <TouchableOpacity style={{flexDirection:'row', justifyContent:'space-between'}} onPress={goWaiterInfo}>
                    <Text style={GSS.mainInfoText}>대기자 정보</Text>
                    <Image
                        source={NextImage}
                        style={{height:25, width:25}}
                    />               
                </TouchableOpacity>
            </View>                                  
            {/* <View style={[GSS.mainViewBox, {flexDirection:'row', alignItems:'center',justifyContent:'space-between'}]}>
                <Text style={GSS.mainInfoText}>회원 관리</Text>
                <TouchableOpacity >
                    <Image
                        source={NextImage}
                        style={{height:25, width:25}}
                    />               
                </TouchableOpacity>
            </View>             */}
        </View>
    );
};

export default HomeOfLogin;