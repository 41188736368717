import React, { useState, useEffect } from 'react';
import logoNetflix from '../assets/images/netflix_logo.png';
import logoDisney from '../assets/images/disney_logo.png';
import logoTiving from '../assets/images/tving_logo.png';
import logoWavve from '../assets/images/wavve_logo.png';
import logoWatcha from '../assets/images/watcha_logo.png';
import iconNetflix from '../assets/images/netflix_icon.png';
import iconDisney from '../assets/images/disney_icon.png';
import iconTiving from '../assets/images/tving_icon.png';
import iconWavve from '../assets/images/wavve_icon.png';
import iconWatcha from '../assets/images/watcha_icon.png';
import profileNetflix from '../assets/images/icon_netflix_profile.png';
import profileNetflix2 from '../assets/images/netflix_profile3.png';
import profileDisney from '../assets/images/icon_disney_profile.png';
import profileTiving from '../assets/images/icon_tving_profile.png';
import profileWavve from '../assets/images/icon_wavve_profile2.png';
import profileWatcha from '../assets/images/icon_watcha_profile.png';
import { SaveToSystem, showToast } from './Global';

const AuthContext = React.createContext();
//const UserContext = React.createContext();
const SystemInfoContext = React.createContext();
const EnvContext = React.createContext();

export const EnvProvider = ({ children }) => {
    const [ENV, setEnv] = useState({
        target_after_login: null,
        login_type: null,
        kakao_id : null,
        user_id : null,
        message_ymd : null,
        check_system_message : false,
        t_item : null,
        item_check_time : null,
        checked_admin : null
    });
    const { login, logout } = useAuth();
    const [envLoadCompleted, setEnvLoadCompleted] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                if(envLoadCompleted) {
                    //console.log('ENV user_id: ', ENV.user_id);
                    if(ENV.user_id != null && ENV.checked_admin === 1) {
                        login();
                    } else {
                        logout();
                    }
                    await SaveToSystem(ENV);
                } else {
                    setEnvLoadCompleted(true);
                }
            } catch (error) {
                console.error("An error occurred:", error);
            }            
        })();        
    }, [ENV.user_id]); 

    const getItemById = (item_id) => {
        if(!ENV.t_item) return null;
        // items가 유효한 배열인지 확인
        if (!Array.isArray(ENV.t_item) || ENV.t_item.length === 0) {
            console.warn(`getItemById: Item is not a valid array[${item_id}]`);
            return null;
        }
        return ENV.t_item.find(item => item.item_id === item_id);
    }; 
    const getItemByName = (item_name) => {
        if(!ENV.t_item) return null;
        if (!Array.isArray(ENV.t_item) || ENV.t_item.length === 0) {
            console.warn(`getItemById: Item is not a valid array[${item_name}]`);
            return null;
        }
        return ENV.t_item.find(item => item.item_name === item_name);
    };     

    return (
        <EnvContext.Provider value={{ ENV, setEnv, getItemById, getItemByName }}>
         {children}
        </EnvContext.Provider>
    );
};

export function useEnv() {
    return React.useContext(EnvContext);
}

export const SystemInfoProvider = ({ children }) => {
    const [systemInfo, setSystemInfo] = useState({
        netflix_price: null,
        disney_price: null,
        tiving_price: null,
        wavve_price: null,
        watcha_price: null,
        system_message: null,
        message_ymd: null,
        trade_charge: null,
        trade_manager_charge: null,
        kakao_client_id: null,
        kakao_redirect_uri: null
    });

    return (
        <SystemInfoContext.Provider value={{ systemInfo, setSystemInfo }}>
         {children}
        </SystemInfoContext.Provider>
    );
};

export function useSystemInfo() {
    return React.useContext(SystemInfoContext);
}


export function AuthProvider({ children }) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
  
    const login = () => {
      setIsLoggedIn(true);
    };
  
    const logout = () => {
      setIsLoggedIn(false);
    };
  
    return (
      <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
        {children}
      </AuthContext.Provider>
    );
}

export function useAuth() {
    return React.useContext(AuthContext);
}

export  function GetOTTByName (item_name) {
    return OTTs.find(item => item.item_name === item_name);
};   

// OTT 목록
export const OTTs = [
    {
        id : 0,
        name : '웨이브',
        item_name : 'wavve',
        item_id : null,
        monthly_price : 3475,
        site_price : 13900,
        logoImage : logoWavve,
        iconImage : iconWavve,
        profileImage : profileWavve,
        price : '월 13,900원',
        discountPrice : '월 3,475원',
        productName : '웨이브 프리미엄',
        immediatelyMatching : false,
        changePwdUrl : 'https://www.wavve.com/index.html',
        homeUrl : 'https://www.wavve.com/index.html',
        loginUrl : 'https://m.wavve.com/login',
        lgoinEndUrl : 'https://m.wavve.com/',
    },
    {
        id : 1,
        name : '디즈니+',
        item_name : 'disney',
        item_id : null,
        monthly_price : 3475,
        site_price : 13900,    
        logoImage : logoDisney,
        iconImage : iconDisney,
        profileImage : profileDisney,
        price : '월 13,900원',
        discountPrice : '월 3,475원',
        productName : '디즈니+ 프리미엄',
        immediatelyMatching : false,
        changePwdUrl : 'https://www.disneyplus.com/ko-kr',
        homeUrl : 'https://www.disneyplus.com/ko-kr',
        loginUrl : 'https://www.disneyplus.com/ko-kr/identity/login/enter-email',
        lgoinEndUrl : '',        
    },
    {
        id : 2,
        name : '왓챠',
        item_name : 'watcha',
        item_id : null,
        monthly_price : 3225,    
        site_price : 12900,
        logoImage : logoWatcha,
        iconImage : iconWatcha,
        profileImage : profileWatcha,
        price : '월 12,900원',
        discountPrice : '월 3,225원',
        productName : '왓챠 프리미엄',
        immediatelyMatching : false,
        changePwdUrl : 'https://watcha.com/',
        homeUrl : 'https://watcha.com/',
        loginUrl : 'https://watcha.com/',       
    },
    {
        id : 3,
        name : '넷플릭스',
        item_name : 'netflix',
        item_id : null,
        monthly_price : 9000,    
        monthly_price2 : 9000,    
        site_price : 17000,    
        logoImage : logoNetflix,
        iconImage : iconNetflix,
        profileImage : profileNetflix,
        profileImage2 : profileNetflix2,
        price : '월 17,000원',
        discountPrice : '월 9,000원',
        discountPrice2 : '월 9,000원',
        productName : '넷플릭스 프리미엄',
        immediatelyMatching : false,
        changePwdUrl : 'https://www.netflix.com/password',
        homeUrl : 'https://www.netflix.com/kr/',
    },
    {
        id : 4,
        name : '티빙',
        item_name : 'tiving',
        item_id : null,
        monthly_price : 4250,
        site_price : 17000,       
        logoImage : logoTiving,
        iconImage : iconTiving,
        profileImage : profileTiving,
        price : '월 17,000원',
        discountPrice : '월 3,475원',
        productName : '티빙 프리미엄',
        immediatelyMatching : false,
        changePwdUrl : 'https://tving.com',
        homeUrl : 'https://tving.com',
        loginUrl : 'https://tving.com',       
    }
];