import { View, Image, StyleSheet, Pressable, Text  } from 'react-native';
import closeImage from '../assets/close.png'

const TopHeader = ( { closeAction, style } ) => {
    return (
        <View style={[SS.container, style]}>
            <Pressable activeOpacity={0.5} onPress={closeAction}>
                <Image
                    source={closeImage}
                    style={{width:25, height:25}}
                />
            </Pressable>
        </View>
    );
};

const SS = StyleSheet.create({
    container: {
      // 컨테이너를 flex 컨테이너로 설정합니다.
      flexDirection: 'row',
      justifyContent: 'flex-end', // 자식 요소들을 컨테이너의 오른쪽으로 정렬합니다.
      alignItems: 'center', // 자식 요소들을 컨테이너의 중앙으로 정렬합니다(수직 방향).
    },

    confirmView: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems : 'center'
    }    
});

export default TopHeader;
