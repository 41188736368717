
function generateRandomEmail(domain) {
    const letters = 'abcdefghijklmnopqrstuvwxyz';
    const numbers = '0123456789';
    let result = '';
    
    for (let i = 0; i < 4; i++) {
        result += letters.charAt(Math.floor(Math.random() * letters.length));
    }
    for (let i = 0; i < 4; i++) {
        result += numbers.charAt(Math.floor(Math.random() * numbers.length));
    }
    return `${result}@${domain}`;
}

export function generateRandomPassword() {
    const upperLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const letters = 'abcdefghijklmnopqrstuvwxyz';
    const numbers = '0123456789';
    const specialCharacters = '!@#$%^&*';
    let alphabetPart = '';
    let numbersPart = '';
    let specialPart = '';

    // 첫 문자는 대문자로
    alphabetPart += upperLetters.charAt(Math.floor(Math.random() * upperLetters.length));

    // 알파벳 2자리
    for (let i = 0; i < 2; i++) {
        alphabetPart += letters.charAt(Math.floor(Math.random() * letters.length));
    }

    // 숫자 4자리
    for (let i = 0; i < 4; i++) {
        numbersPart += numbers.charAt(Math.floor(Math.random() * numbers.length));
    }

    // 특수문자 2자리
    for (let i = 0; i < 2; i++) {
        specialPart += specialCharacters.charAt(Math.floor(Math.random() * specialCharacters.length));
    }

    // 첫 번째 위치에 올 무리 결정 (알파벳 또는 숫자)
    let firstPart = Math.random() < 0.5 ? alphabetPart : numbersPart;
    let secondAndThirdParts = firstPart === alphabetPart ? [numbersPart, specialPart] : [alphabetPart, specialPart];

    // 두 번째와 세 번째 위치에 올 무리 섞기
    secondAndThirdParts.sort(() => Math.random() - 0.5);

    // 최종 비밀번호 조합
    return firstPart + secondAndThirdParts.join('');
}