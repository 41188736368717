import React, { useEffect } from 'react';
import { SafeAreaView, Platform} from "react-native";
import { WebView } from 'react-native-webview';
import { useEnv } from './AppContext';
import { routerServer } from './Global';
import GSS from './GlobalStyleSheet2.js'
import { useLoginProc } from './LoginProc2';

// const runFirst = `window.ReactNativeWebView.postMessage("this is message from web");`;

export default function LoginApple({navigation, route}) {
    const {ENV} = useEnv();
    const { loginProc } = useLoginProc();

    const newENV = {
        user_id : null,
        last_login_type : null
    }

    onMessage = (event) => {
        const uri = event.nativeEvent.url;
        //console.log(uri);
        if(uri.startsWith(routerServer))
        {
            // 서버로부터의 응답 데이터 처리
            const ret = JSON.parse(event.nativeEvent.data);
            newENV.user_id = ret.user_id;
            newENV.last_login_type = ret.last_login_type;
            navigateAfterLogin();
        }        
    }

    const navigateAfterLogin = async () => {
        //console.log( newENV.user_id);
        if( newENV.user_id) {
            await loginProc(newENV);
        } 
        navigation.navigate(ENV.target_after_login);   
    };    
    
    return (
        <SafeAreaView style={GSS.droidSafeArea}>
            <WebView 
                originWhitelist={['*']}
                scalesPageToFit={false}
                source={{ uri: `${routerServer}/apple/call_apple`, webSecurityEnabled: false }}
                javaScriptEnabled={true}
                onMessage={onMessage}
                //source={{ html: htmlContent }}
            />
        </SafeAreaView>
    );    
}