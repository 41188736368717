import React, { useEffect, useState} from 'react';
import { View, ImageBackground, StyleSheet, Alert, ActivityIndicator } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useSystemInfo, useAuth, useEnv } from './AppContext';
import Axios from "axios"
import { LoadFromSystem, routerServer } from './Global';
import { useLoginProc } from './LoginProc2';

function SplashScreen() {
    const { systemInfo, setSystemInfo } = useSystemInfo();
    //const { isLoggedIn, login, logout } = useAuth();
    const { ENV, setEnv } = useEnv();
    const navigation = useNavigation();
    const [isLoading, setIsLoading] = useState(true); // 초기 상태에서는 로딩 중으로 설정
    const { loginProc } = useLoginProc();

    const loadFromSystem = async (newENV) => {
        const sysData = await LoadFromSystem();
        //console.log(' SplashScreen : LoadFromSystem() : ', sysData);
        if(sysData && sysData.user_id ) {
            newENV.user_id = sysData.user_id;
            newENV.login_type = sysData.login_type;
            newENV.last_login_type =sysData.last_login_type;
            // newENV.kakao_id = sysData.kakao_id;
            // newENV.naver_id = sysData.naver_id;
            newENV.message_ymd = sysData.message_ymd;
            newENV.t_item = sysData.t_item;
            newENV.item_check_time = sysData.item_check_time;
        }
        return sysData;
    };

    const fetchSystemInfo = async () => {
        try {
            const response = await Axios.get(`${routerServer}/system_info`, {
                headers: {
                    "content-type": "application/json",
                },
            });
    
            if (response.status === 200) {
                setSystemInfo(response.data);
                setSystemInfo(prevState => ({ ...prevState, ...response.data }));
                return response.data;
            } else {
                throw new Error('Fetching system_info failed');
            }
        } catch (error) {
            console.error(error);
            //Alert.alert('Error', 'Failed to fetch system info');
            throw error;
        }
    };
    
    const fetchAllItems = async () => {
        try {
            const response = await Axios.get(`${routerServer}/get_items`, {
                headers: {
                    "content-type": "application/json",
                },
            });
    
            if (response.status === 200) {
                return response.data;
            } else {
                throw new Error('Fetching get_items failed');
            }
        } catch (error) {
            console.error(error);
            throw error;
        }
    };
    
    useEffect(() => {
        let isMounted = true;
    
        const initialize = async () => {
            try {
                if (isMounted) {
                    setIsLoading(true);
                    const newENV = {};
                    const sysData = await loadFromSystem(newENV);
                    //console.log('SplashScreen : LoadFromSystem() : ', sysData);
                    //console.log(newENV);

                    const newSystemInfo = await fetchSystemInfo();
                    //console.log('newSystemInfo : ', newSystemInfo);
                    if( sysData && sysData.message_ymd)
                    {
                        //  console.log(sysData.message_ymd);
                        //  console.log(newSystemInfo.message_ymd);
                        if( new Date(sysData.message_ymd) < new Date(newSystemInfo.message_ymd)) {
                            newENV.check_system_message = true;
                        }
                    } else {
                        newENV.check_system_message = true;
                    }
                    //console.log('newSystemInfo.item_last_update : ', newSystemInfo.item_last_update);
                    let checking = false;
                    if( sysData && sysData.item_check_time ) {
                        //console.log('newSystemInfo.item_last_update : ', newSystemInfo.item_last_update);
                        if(newSystemInfo.item_last_update) {
                            const currentLastDate = new Date(sysData.item_check_time);
                            const item_last_update = new Date(newSystemInfo.item_last_update);
                            if( item_last_update > currentLastDate)
                            {
                                checking = true;
                            }
                        } 
                        // else {
                        //     console.log(ENV.item_check_time);
                        // }
                    } else {
                        checking = true;
                    }
                    //if (checking) {
                    if (true) {
                        const items = await fetchAllItems();
                        if( items) {
                            //console.log(items);
                            newENV.t_item = [...items];
                            newENV.item_check_time = newSystemInfo.item_last_update;
                        } else {
                            console.warn('SplashScreen.fetchAllItems error : ');
                        }
                    }
                    // console.log('SplashScreen newENV :', newENV);
                    setIsLoading(false);
                    goNavi(newENV);
                }
           } catch (e) {
                console.error(e);
                Alert.alert("Error", e.toString());
                if (isMounted) {
                    setIsLoading(false);
                    navigation.navigate('home');
                }
            }
            navigation.reset({
                index: 0,
                routes: [{ name: 'home' }],
              });  
        };
    
        initialize();
    
        return () => {
            isMounted = false;
        };
    }, []);

    const goNavi = async (newEnv) => {
        // console.log('SplashScreen goNavi() entered');
        await loginProc(newEnv);
        // console.log('SplashScreen goNavi() go home');
        navigation.reset({
            index: 0,
            routes: [{ name: 'home' }],
          });  
    };

    return (
        <View style={styles.container}>
        {isLoading && (
            <View style={styles.activityIndicatorContainer}>
                <ActivityIndicator size="large" color="#0000ff" />
            </View>
        )}
        </View>        
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    activityIndicatorContainer: {
        flex: 1,
        justifyContent: 'center', // 세로 축에서 중앙에 위치
        alignItems: 'center', // 가로 축에서 중앙에 위치
    },     
});

export default SplashScreen;