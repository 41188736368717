import React from 'react';
import { Text, View, Image, Platform, Dimensions, TouchableOpacity } from 'react-native';
import GSS from '../GlobalStyleSheet2'
import { OTTs, useEnv} from '../AppContext';
import { ProgressButtonOrg } from '../ProgressButton';
import { useNavigation  } from '@react-navigation/native';
import NextImage from '../../assets/next.png';

function HomeOfLogout () {
    const { height, width: screenWidth } = Dimensions.get('window');
    const navigation = useNavigation();
    const { ENV } = useEnv();

    const LogIn = () => {
        ENV.target_after_login = 'Home';
        if( Platform.OS === 'web')
            navigation.navigate('LoginWeb');
        else
            navigation.navigate('Login');
    }

    return (
        <View style={[GSS.mainContainer, {height: height}]}>
            <View style={[GSS.mainViewBox, {flexDirection:'row', alignItems:'center',justifyContent:'space-between'}]}>
                <Text style={GSS.mainTitleText}>로그인</Text>
                <TouchableOpacity onPress={LogIn}>
                    <Image
                        source={NextImage}
                        style={{height:25, width:25}}
                    />               
                </TouchableOpacity>
            </View>
        </View>
    );
};

export default HomeOfLogout;