import React, {useState, useEffect, useRef} from 'react';
import { Image, Text, View, StyleSheet, Dimensions, TouchableOpacity, ActivityIndicator } from 'react-native';
import GSS from '../GlobalStyleSheet2';
import CloseHeader from '../CloseHeader';
import { showToast } from '../Global';
import { useModal } from '../ModalContext';

import * as Clipboard from 'expo-clipboard';
import { WebView } from 'react-native-webview';

function NetflixMatchedProc ({navigation, route}) {
    const { user_item, item, userAccounts } = route.params;
    const { confirmModal, showMessage } = useModal();
    const [isLoading, setIsLoading] = useState(false);
    const [ email, setEmail] = useState();

    const [ accountName, setAccountName] = useState();
    const [ curStep, SetCurStep] = useState(0);
    const [ stepTitle, SetStepTitle] = useState('');
    // 다음 버튼 처리에 대한 셋팅딩
    const [countdown, setCountdown] = useState(0);
    const [isActive, setIsActive] = useState(true);
    const [intervalId, setIntervalId] = useState(null);
    const [ stepButtonTitle, setStepButtonTitle] = useState('...');
    /////
    const [ source , setSource] = useState({ uri: `https://www.netflix.com/kr/login`, webSecurityEnabled: false });
    const [currentUrl, setCurrentUrl] = useState(null); // 현재 URL 상태
    const webviewRef = useRef(null);
    const [canGoBack, setCanGoBack] = useState(false);
    const [canGoForward, setCanGoForward] = useState(false);        

    const _STEP = [
        {   // 0
            step_title : '넷플릭스에 로그인해 주세요',
            step_url : 'https://www.netflix.com/kr/login'
        },
        {   // 1
            step_title : '넷플릭스에 로그인해 주세요',
            step_url : 'https://www.netflix.com/browse'
        },
        {   // 2
            step_title : '추가회원 자리를 구매 해 주세요',
            step_url : 'https://www.netflix.com/YourAccount'
        },
        {   // 3, 추가 회원이 등록 되었다.
            stepTitle : '추가회원을 초대해주세요',
            step_url : 'https://www.netflix.com/accountowner/extramemberadded'
        },
        {   // 4. 추가회원 등록 화면
            stepTitle : '추가회원을 초대해주세요',
            step_url : 'https://www.netflix.com/accountowner/setup'            
        },
        {   // 5. 추가회원 등록 화면
            stepTitle : '추가회원을 초대해주세요',
            step_url : 'https://www.netflix.com/accountowner/newprofile'            
        },
        {   // 6. 완료
            stepTitle : '추가회원 초대가 완료되었습니다',
            step_url : 'https://www.netflix.com/accountowner/success'            
        }        
    ];

    const onNavigationStateChange = async (navState) => {
        setCanGoBack(navState.canGoBack);
        setCanGoForward(navState.canGoForward);
        if (currentUrl !== navState.url) {
            // 새로운 URL로 변경됨
            //console.log("URL 변경됨:", navState.url);
            setCurrentUrl(navState.url); // 현재 URL 상태 업데이트
            // 여기에 추가 로직을 구현할 수 있습니다.
            if( navState.url === _STEP[0].step_url) {
                if(user_item.account_userid ) {
                    await copyToClipboard(user_item.account_userid);
                    showMessage([
                        `넷플릭스 계정 [${user_item.account_userid}]을 복사하였습니다.`,
                        '이메일 주소란에 붙여넣기 하시고 비밀번호도 복사하시여 붙여 넣으세요',
                        '넷플릭스를 로그인 해 주세요'
                    ]);
                } else {
                    showMessage([
                        '계정 정보를 입력하시고 넷플릭스를 로그인 해 주세요'
                    ]);                        
                }
            } else if( navState.url === _STEP[1].step_url) {
                SetCurStep(1);
                setSource({ uri: _STEP[2].step_url, webSecurityEnabled: false });
            } else if( navState.url === _STEP[2].step_url) {
                SetCurStep(2);
                setStepButtonTitle('다음');
                showMessage([
                    `추가회원 자리를 구매 해 주세요.`,
                    '이미 추가회원 자리가 있으면 다음을 클릭하세요',
                ]);                
            } else if( navState.url === _STEP[4].step_url) {
                SetCurStep(4);
                setStepButtonTitle('...');
                await copyToClipboard(email);
                showMessage([
                    `추가회원 계정 [${email}]을 복사하였습니다(지정된 이메일을 사용치 않으면 공유가 불가능합니다)`,
                    `이름란에 아래 값을 입력하시고, 이메일주소 입력란에는 바로 붙여넣기를 하세요`,
                    `입력이 완료 되었으면 '다음' 버튼을 클릭하여 진행해 주세요`,
                ]);                
            } else if( navState.url === _STEP[5].step_url) {
                SetCurStep(5);
                showMessage([
                    `추가회원은 프로필은 '새 프로필' 그대로 사용하세요`,
                    `'다음' 버튼을 클릭하여 진행해 주세요`,
                ]);                
            } else if( navState.url === _STEP[6].step_url) {
                SetCurStep(6);
                setStepButtonTitle('완료');
                showMessage([
                    `초가회원 초대가 완료되었습니다`,
                    `자동매칭을 통한 편한 공유가 진행됩니다.`,
                    `'완료' 버튼을 클릭하여 종료하세요`,
                ]);                
            }
        }
     };

    const goBack = () => {
        webviewRef.current.goBack();
    };

    const goForward = () => {
        webviewRef.current.goForward();
    };       

    useEffect(() => {
        if (countdown === 0 && intervalId) {
            clearInterval(intervalId);
            setIsActive(true);
        }
    }, [countdown, intervalId]);

    const startCountdown = () => {
        if (isActive) {
            setIsActive(false);
            setCountdown(20);
            const id = setInterval(() => {
                setCountdown(prevCount => prevCount - 1);
            }, 1000);
            setIntervalId(id);
        }
    };

    const stopCountdown = () => {
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
            setCountdown(0);
            setIsActive(true);
        }
    };        

    useEffect(() => {
         //console.log(user_item);
        // console.log(userAccounts);
        if(userAccounts ) {
            if(userAccounts[0].account_id === user_item.account_email_id ) {
                setAccountName('1');
            } else {
                setAccountName('2');
            }
            setEmail(userAccounts[0].account_email);
        }
    }, []);    

    const CloseButton = () => {
        navigation.goBack();
    };    

    const copyToClipboard = async (text) => {
        await Clipboard.setStringAsync(text);
        //showToast('클립보드에 복사되었습니다.');
    };    
    
    const _completeProc = async () => {
        try {
        } catch(error) {
            console.log(error);
        }
    }

    const completeProc = () => {
        //console.log(curStep);
        if(curStep === 2) {
            setSource({ uri: _STEP[4].step_url, webSecurityEnabled: false });
            return;
        }
        if(curStep === 6) {
            navigation.goBack();
            return;
        }        
    }    

    const windowWidth = Dimensions.get('window').width * 0.8;
    return (
        <View style={GSS.mainContainer}>
            <CloseHeader
                closeAction={CloseButton}
            />
            <View style={[GSS.mainViewBoxNoBorder, {marginTop:5}]}>
                <Text style={[GSS.mainDefaultTextB]}>
                    {_STEP[curStep].step_title}
                </Text>
            </View>
            <View style={{flexDirection:'row'}}>
                <View style={{flex:1, borderWidth:0.25, alignItems:'center', justifyContent:'center'}}>
                    <TouchableOpacity onPress={goBack} disabled={!canGoBack}>
                        <Text style={[GSS.mainDefaultText, {color: canGoBack ? '#291B44' : '#c4c4c4'}]}>{'<< 뒤로'}</Text>
                    </TouchableOpacity>
                </View>
                <View style={{flex:1, borderWidth:0.25, alignItems:'center', justifyContent:'center'}}>
                    <TouchableOpacity onPress={goForward} disabled={!canGoForward}>
                        <Text style={[GSS.mainDefaultText, {color: canGoForward ? '#291B44' : '#c4c4c4'}]}>{'앞으로 >>'}</Text>
                    </TouchableOpacity>
                </View>
            </View>
            <WebView 
                ref={webviewRef}
                onNavigationStateChange={onNavigationStateChange}
                style={{flex:1}}
                //scalesPageToFit={false}
                source={source}
                originWhitelist={['*']}
            />
            <View style={{flexDirection:'row', marginTop:5}}>
                <View style={{flex:1}}>
                    <View style={[SS.textContainer]}>
                        { curStep === 0 && (
                            <React.Fragment>
                                <View style={{flexDirection:'row'}}>
                                    <View style={{width:45}}>
                                        <Text style={GSS.mainDefaultText}>계정</Text>
                                    </View>
                                    <View style={{flex:1, }}>
                                        <Text style={GSS.mainInfoTextB}>{user_item.account_userid}</Text>
                                    </View>
                                    <TouchableOpacity style={{flex:0.2}}  onPress={() => copyToClipboard(user_item.account_userid)}>
                                        <Text style={GSS.mainInfoText}>복사</Text>
                                    </TouchableOpacity>
                                </View>
                                <View style={{flexDirection:'row', marginBottom:5}}>
                                    <View style={{width:45}}>
                                        <Text style={GSS.mainDefaultText}>비번</Text>
                                    </View>
                                    <View style={{flex:1, }}>
                                        <Text style={GSS.mainInfoTextB}>{user_item.account_pwd}</Text>
                                    </View>
                                    <TouchableOpacity style={{flex:0.2}}   onPress={() => copyToClipboard(user_item.account_pwd)}>
                                        <Text style={GSS.mainInfoText}>복사</Text>
                                    </TouchableOpacity>
                                </View>               
                            </React.Fragment>
                        )}
                        { curStep === 2 && (
                            <React.Fragment>
                                <View style={{alignItems:'center', justifyContent:'center'}}>
                                    <Text style={GSS.mainInfoTextB}>추가회원 구매 후 다음을 클릭</Text>
                                </View>
                            </React.Fragment>
                        )}                        
                        { curStep === 4 && (
                            <React.Fragment>
                                <View style={{flexDirection:'row', marginBottom:5}}>
                                    <View style={{width:45}}>
                                        <Text style={GSS.mainDefaultText}>이름</Text>
                                    </View>
                                    <View style={{flex:1, }}>
                                        <Text style={GSS.mainInfoTextB}>{accountName}</Text>
                                    </View>
                                    <TouchableOpacity style={{flex:0.2}} onPress={() => copyToClipboard(accountName)}>
                                        <Text style={GSS.mainInfoText}>복사</Text>
                                    </TouchableOpacity>
                                </View>               
                                <View style={{flexDirection:'row'}}>
                                    <View style={{width:45}}>
                                        <Text style={GSS.mainDefaultText}>이메일</Text>
                                    </View>
                                    <View style={{flex:1, }}>
                                        <Text style={GSS.mainInfoTextB}>{email}</Text>
                                    </View>
                                    <TouchableOpacity style={{flex:0.2}} onPress={() => copyToClipboard(email)}>
                                        <Text style={GSS.mainInfoText}>복사</Text>
                                    </TouchableOpacity>
                                </View>
                            </React.Fragment>
                        )}                        
                    </View>
                </View>
                <View style={{marginLeft:5, alignItems:'center', justifyContent:'center', flex:0.2, borderWidth:0.5, borderRadius:10,
                    backgroundColor: (!isActive || stepButtonTitle==='...') ? '#eFeCe3' : '#017cf7'}}>
                    <TouchableOpacity 
                        disabled={!isActive || stepButtonTitle === '...'} 
                        onPress={completeProc}
                    >
                        <Text style={[GSS.mainBigText, {color:'#ffffff'}]}>{isActive ? stepButtonTitle : `${countdown}`}</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>   
    );
};

const SS = StyleSheet.create({
    container: {
      // 컨테이너를 flex 컨테이너로 설정합니다.
      flex: 1,
      justifyContent: 'flex-end', // 자식 요소들을 컨테이너의 오른쪽으로 정렬합니다.
      alignItems: 'center', // 자식 요소들을 컨테이너의 중앙으로 정렬합니다(수직 방향).
      borderWidth:1,
      borderColor : '#8F8C83',
      margin:5,
      borderRadius:10,
      marginBottom : 15
    },
    confirmView: {
        //flex: 
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems : 'center',
        marginBottom : 10
    },
    textContainer: {
        alignItems:'center',
        padding:10, 
        borderWidth:1,
        borderRadius:5,
        borderColor: '#8F8C83'
    }
});

export default NetflixMatchedProc;