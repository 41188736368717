import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { SafeAreaProvider, SafeAreaView,  } from 'react-native-safe-area-context';
import React, {useState, useEffect} from 'react';
import { View, Text, Platform, AppState, TouchableOpacity, Image} from 'react-native';
import { NavigationContainer, useNavigation } from "@react-navigation/native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { AuthProvider, SystemInfoProvider, EnvProvider } from './components/AppContext.js';
import { ModalProvider } from './components/ModalContext.js';
import SplashScreen from './components/SplashScreen';
import Home from './components/Home/Home.js';
import MyInfoHome from './components/MyInfo/MyInfoHome.js';
import Login from './components/Login.js';
import LoginKakao from './components/LoginKakao.js';
import LoginNaver from './components/LoginNaver.js';
import LoginRedirect from './components/LoginRedirect.js';
import LoginApple from './components/LoginApple.js';
import PayHome from './components/Pay/PayHome.js';
import UserInfo from './components/Home/UserInfo.js';
import UserItemInfo from './components/Home/UserItemInfo.js';
import UsedItemInfo from './components/Home/UsedItemInfo.js';
import WaiterInfo from './components/Home/WaiterInfo.js';
import AutoPay from './components/Pay/AutoPay.js';
import PayResult from './components/Pay/PayResult.js';
import LoginWeb from './components/LoginWeb.js';
import { useEnv } from './components/AppContext';
import AccountHome from './components/Account/AccountHome.js';
import AdminAccount from './components/Account/AdminAccount.js';
import NetflixMatchedProc from './components/Account/NetflixMatchedProc.js';
import MyshottEmail from './components/Account/MyshottEmail.js';
import CheckEmailContent from './components/Home/CheckEmailContent.js';
import verify19 from './components/Home/verify19.js';

const Tab = createBottomTabNavigator();
const MyInfoStack = createNativeStackNavigator();
const HomeStack = createNativeStackNavigator();
const PayStack = createNativeStackNavigator();
const AccountStack = createNativeStackNavigator();

const navigationRef = React.createRef();
export function navigateToTab(tabName) {
  navigationRef.current?.navigate(tabName);
}

const LoginScreens = [
  { name: 'Login', component: Login, options: { headerShown: false } },
  { name: 'LoginKakao', component: LoginKakao, options: { headerShown: false } },
  { name: 'LoginNaver', component: LoginNaver, options: { headerShown: false } },
  { name: 'LoginRedirect', component: LoginRedirect, options: { headerShown: false } },
  { name: 'LoginApple', component: LoginApple, options: { headerShown: false } },
  { name: 'LoginWeb', component: LoginWeb, options: { headerShown: false } },
  // 추가적인 공유 스크린들이 있다면 여기에 추가하세요.
];

const MyInfoStackNavigation = () => {
  return (
    <MyInfoStack.Navigator
      initialRouteName={'MyInfoHome'}
      // headerShown={false}
    >
      <MyInfoStack.Screen
        name={'MyInfoHome'}
        component={MyInfoHome}
        options={{
          headerShown: false,
        }}
      />
        
      {LoginScreens.map((screen) => (
        <MyInfoStack.Screen 
          key={screen.name}
          name={screen.name} 
          component={screen.component} 
          options={screen.options} 
        />
      ))}
    </MyInfoStack.Navigator>
  );
}

const AccountStackNavigation = () => {
  return (
    <AccountStack.Navigator
      initialRouteName={'AccountHome'}
      // headerShown={false}
    >
      <AccountStack.Screen
        name={'AccountHome'}
        component={AccountHome}
        options={{
          headerShown: false,
        }}
      />
      <AccountStack.Screen
        name={'AdminAccount'}
        component={AdminAccount}
        options={{
          headerShown: false,
        }}
      />        
      <AccountStack.Screen
        name={'NetflixMatchedProc'}
        component={NetflixMatchedProc}
        options={{
          headerShown: false,
        }}
      />
      <AccountStack.Screen
        name={'MyshottEmail'}
        component={MyshottEmail}
        options={{
          headerShown: false,
        }}
      />   
      {LoginScreens.map((screen) => (
        <AccountStack.Screen 
          key={screen.name}
          name={screen.name} 
          component={screen.component} 
          options={screen.options} 
        />
      ))}
    </AccountStack.Navigator>
  );
}


const PayStackNavigation = () => {
  return (
    <PayStack.Navigator
      initialRouteName={'PayHome'}
      // headerShown={false}
    >
      <PayStack.Screen
        name={'PayHome'}
        component={PayHome}
        options={{
          headerShown: false,
        }}
      /> 
      <PayStack.Screen
        name={'AutoPay'}
        component={AutoPay}
        options={{
          headerShown: false,
        }}
      />  
      <PayStack.Screen
        name={'PayResult'}
        component={PayResult}
        options={{
          headerShown: false,
        }}
      />         
      {LoginScreens.map((screen) => (
        <PayStack.Screen 
          key={screen.name}
          name={screen.name} 
          component={screen.component} 
          options={screen.options} 
        />
      ))}
    </PayStack.Navigator>
  );
}

const HomeStackNavigation = () => {
  return (
    <HomeStack.Navigator
      initialRouteName={'Home'}
      // headerShown={false}
    >
      <HomeStack.Screen
        name={'Home'}
        component={Home}
        options={{
          headerShown: false,
        }}
      /> 
      <HomeStack.Screen
        name={'UserInfo'}
        component={UserInfo}
        options={{
          headerShown: false,
        }}
      />
      <HomeStack.Screen
        name={'UserItemInfo'}
        component={UserItemInfo}
        options={{
          headerShown: false,
        }}
      />   
      <HomeStack.Screen
        name={'UsedItemInfo'}
        component={UsedItemInfo}
        options={{
          headerShown: false,
        }}
      />  
      <HomeStack.Screen
        name={'WaiterInfo'}
        component={WaiterInfo}
        options={{
          headerShown: false,
        }}
      />
      <HomeStack.Screen
        name={'CheckEmailContent'}
        component={CheckEmailContent}
        options={{
          headerShown: false,
        }}
      /> 
      <HomeStack.Screen
        name={'verify19'}
        component={verify19}
        options={{
          headerShown: false,
        }}
      />                             
      {LoginScreens.map((screen) => (
        <HomeStack.Screen 
          key={screen.name}
          name={screen.name} 
          component={screen.component} 
          options={screen.options} 
        />
      ))}
    </HomeStack.Navigator>
  );
}

function RootNavigator() {
  function MyTabBar({ state, descriptors, navigation }) {
    return (
      <View style={ {flexDirection: 'row', backgroundColor: '#FFFFFF', borderWidth:1, borderColor: '#e3e3e3'}}>
        {state.routes.map((route, index) => {
          const { options } = descriptors[route.key];

          if(options.tabBarStyle?.display == 'none') {
            return null;
          }
  
          if (route.name === 'SplashScreen') {
            return null; // SplashScreen 탭을 렌더링하지 않음
          }
  
          const label =
            options.tabBarLabel !== undefined
              ? options.tabBarLabel
              : options.title !== undefined
              ? options.title
              : route.name;
  
          const isFocused = state.index === index;
  
          const onPress = () => {
            const event = navigation.emit({
              type: 'tabPress',
              target: route.key,
              canPreventDefault: true,
            });
  
            if (!isFocused && !event.defaultPrevented) {
              navigation.navigate({ name: route.name, merge: true });
            }
          };
  
          const onLongPress = () => {
            navigation.emit({
              type: 'tabLongPress',
              target: route.key,
            });
          };
  
          // Define the image source and style
          const tabIcon = 
            route.name === 'home'? require('./assets/home.png') 
            : route.name === 'Pay'? require('./assets/payment.png')
            : route.name === 'Account'? require('./assets/user.png')
            : route.name === 'MyInfo'? require('./assets/user.png')
            : null; // Add more conditions as needed
                    
          return (
            <TouchableOpacity
              accessibilityRole="button"
              accessibilityState={isFocused ? { selected: true } : {}}
              accessibilityLabel={options.tabBarAccessibilityLabel}
              testID={options.tabBarTestID}
              onPress={onPress}
              onLongPress={onLongPress}
              style={{ flex: 1, alignItems: 'center', marginBottom:-2,marginTop:2 }}
              key={route.key} // 각 탭에 고유한 key 추가
            >
              {tabIcon && (
                <Image
                  source={tabIcon}
                  style={{ width: 18, height: 18, tintColor: isFocused ? '#4876D6' : '#222' }}
                />
              )}
              <Text style={{ color: isFocused ? '#4876D6' : '#222'}}>
                {label}
              </Text>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  }  
  return (
    <NavigationContainer ref={navigationRef}>
      <Tab.Navigator 
          screenOptions={({ route }) => ({
            headerShown: false,
          })}  
          tabBar={props => 
            <MyTabBar {...props} />
          }           
      >
        <Tab.Screen name="SplashScreen" component={SplashScreen} 
            options={{ headerShown: false }}
        />     
        <Tab.Screen name="home" component={HomeStackNavigation} 
          options={{
            title: "홈",
          }}
        />
        <Tab.Screen name="Pay" component={PayStackNavigation} 
          options={{
            title: "결제",
          }}
        />        
        <Tab.Screen name="Account" component={AccountStackNavigation} 
          options={{
            title: "계정",
          }}        
        />        
        <Tab.Screen name="MyInfo" component={MyInfoStackNavigation} 
          options={{
            title: "내정보",
          }}        
        />        
      </Tab.Navigator>
    </NavigationContainer>
  );
}

const CheckAppState = () => {
  const { ENV, setEnv } = useEnv();

  const [appState, setAppState] = useState(AppState.currentState);
  const LOGOUT_TIME = 300000 * 2; // 10분 (밀리초 단위)
  
  useEffect(() => {
    if(Platform.OS === 'web') {
      document.addEventListener("visibilitychange", function() {
        if (document.visibilityState === 'visible') {
          //console.log("웹 페이지가 활성화되었습니다.");
          clearTimeout(this.logoutTimer);
        } else {
          //console.log("웹 페이지가 비활성화되었습니다.");
          this.logoutTimer = setTimeout(handleLogout, LOGOUT_TIME);
        }
      });
    } else {
      const handleAppStateChange = nextAppState => {
        if (appState.match(/inactive|background/) && nextAppState === 'active') {
            // 앱이 포그라운드로 돌아올 때 타이머 초기화
            clearTimeout(this.logoutTimer);
        } else if (nextAppState === 'background') {
            // 앱이 백그라운드로 전환될 때 타이머 설정
            //console.log('백그라운드로 전환');
            this.logoutTimer = setTimeout(handleLogout, LOGOUT_TIME);
        }
        setAppState(nextAppState);
      };

      // AppState 변경을 위한 리스너 추가 및 구독 객체 반환
      const subscription = AppState.addEventListener('change', handleAppStateChange);

      // Cleanup 함수에서 구독을 해제
      return () => {
          subscription.remove();
          clearTimeout(this.logoutTimer);
      };
    }
  }, [appState]);
  
  const handleLogout = () => {
      // 로그아웃 처리 로직
      const newENV = { ...ENV };
      newENV.user_id = null;
      newENV.checked_admin = null;
      newENV.last_login_type = null;
      newENV.checked_admin = null;
      setEnv(newENV);
  };
    
  return null;
}


export default function App() {
  return (
    <SafeAreaProvider>
        <GestureHandlerRootView style={{ flex: 1 }}> 
          <SystemInfoProvider>
            <AuthProvider>
              <EnvProvider>
                <ModalProvider>
                  <SafeAreaView style={{flex: 1}}>
                    <CheckAppState/>
                    { Platform.OS === 'web' ? (
                      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center',}}>
                        <View style={{maxWidth: '800px', width: '100%', flex:1, }}>
                          <View style={{flex:1, backgroundColor: 'lightgray',}} >
                            <RootNavigator />
                          </View>
                        </View>
                      </View>
                    ) : (
                      <View style={{flex:1}} >
                        <RootNavigator />
                      </View>
                    )}
                 </SafeAreaView>     
                </ModalProvider>
              </EnvProvider>
            </AuthProvider>
          </SystemInfoProvider>
        </GestureHandlerRootView>
    </SafeAreaProvider>
  );
}
