import { useEnv } from './AppContext';
import { GetRow } from './api';

export const useLoginProc = () => {
    const { setEnv } = useEnv();

    const loginProc = async (newENV) => {
        try {
            // setEnv({
            //     ...ENV,
            //     ...newENV
            // });
            if( newENV.user_id !== null) {
                const ret = await GetRow('select checked_admin from t_user where user_id=?', [newENV.user_id]);
                if( ret.success ) {
                  newENV.checked_admin = ret.data?.checked_admin;
                  //console.log(newENV.checked_admin);
                }
            }   
            setEnv(prevEnv => ({
              ...prevEnv,
              ...newENV
            }));            
   
          } catch(e) {
            console.log('LoginProc error', e);
            //Alert.alert('LoginProc error', e)
          }        
    }

    return { loginProc };
};