import React, {useState, useEffect} from 'react';
import { Text, View, TouchableOpacity , Image, Dimensions, ActivityIndicator, FlatList } from 'react-native';
import { useAuth, useEnv, GetOTTByName} from '../AppContext';
import GSS from '../GlobalStyleSheet2'
import NextImage from '../../assets/next.png';
import { GetRow, GetRows} from '../api';
import TopHeader from '../TopHeader';
import { useFocusEffect } from '@react-navigation/native';
import CheckBox from 'expo-checkbox';
import refreshImage from '../../assets/refresh.png';
import { HorizontalLine, formatDate } from '../Global';
import { CancelWaitingFromDB } from '../api';

function WaiterInfo ({navigation, route}) {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMoreData, setHasMoreData] = useState(true);
    const { isLoggedIn } = useAuth();
    const [selectedItem, setSelectedItem] = useState(null);
    const { getItemById } = useEnv();
    const [ refreshOK, SetRefreshOK] = useState(false);

    useFocusEffect(
        React.useCallback(() => {
            if(isLoggedIn!==true) {
                navigation.reset({
                    index: 0,
                    routes: [{ name: 'home' }],
                });                
            }
        }, [isLoggedIn]) // 여기서는 의존성 배열을 비워둡니다.
    );       

    const fetchData = async () => {
        if (isLoading || !hasMoreData) return;
        setIsLoading(true);
        try {
            const itemsPerPage = 25;
            const offset = (page - 1) * itemsPerPage;
            const sql = `SELECT * FROM t_waiting_list ORDER BY request_at DESC LIMIT ${itemsPerPage} OFFSET ${offset}`;
            const ret = await GetRows(sql);
            if (ret.success) {
                const newData = ret.data;
                if (newData.length > 0) {
                    setData(prevData => [...prevData, ...newData]);
                    setPage(prevPage => prevPage + 1);
                } else {
                    setHasMoreData(false);
                }
            }
        } catch (e) {
            console.error(e);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if(refreshOK) {
            fetchData();
            SetRefreshOK(false);
        }
    }, [refreshOK]);     

    const loadMoreData = () => {
        fetchData();
    };

    const refreshProc = () => {
        setData([]); // 데이터 배열을 비웁니다.
        setPage(1); // 페이지 번호를 초기화합니다.
        setHasMoreData(true); // 더 많은 데이터가 있다고 가정합니다.
        setSelectedItem(false);
        //await fetchData(); // 데이터를 다시 불러옵니다.
        SetRefreshOK(true);
    };    

    const handleSelectItem = (item) => {
        setSelectedItem(selectedItem === item ? null : item);
    };
    
    const deleteWaiting = async () => {
        setIsLoading(true);
        try {
            const ret = await CancelWaitingFromDB(selectedItem.waiting_id);
            if( ret.success )
            {
                refreshProc();
            }
        } catch(e) {

        }
        setIsLoading(false);
    }

    const displayUserInfo = (user_id) => {
        navigation.navigate('UserInfo', { target_user_id: user_id });
    }      

    function RenderSelectedItem ({item}) {
        if(selectedItem === null) return null;
        const Item = getItemById(item.item_id);
        return (
            <View style={[GSS.mainViewBox, {borderWidth:1}]}>
                <View style={{flexDirection: 'row', }}>
                    <View style={{flex: 0.3}}>
                        <Text style={GSS.mainDefaultText}>{item.waiting_id}</Text>
                    </View>
                    <View style={{flex: 0.3}}>
                        <Text style={GSS.mainDefaultTextB}>{item.user_id}</Text>
                    </View>
                    <View style={{flex: 0.4}}>
                        <Text style={GSS.mainDefaultText}>{Item?.item_name}</Text>
                    </View>                     
                    <View style={{flex: 1}}>
                        <Text style={GSS.mainDefaultText}>{item.using_info}</Text>
                    </View>
                    {/* <View style={{flex: 1}}>
                        <Text style={GSS.mainDefaultText}>{formatDate(item.request_at)}</Text>
                    </View>                 */}
                </View>
                <HorizontalLine
                    thickness={0.5}
                />
                <View style={{flexDirection: 'row', justifyContent:'space-evenly', alignItems:'center' }}>
                    <TouchableOpacity
                        onPress={deleteWaiting}
                    >
                        <Text style={GSS.labelContainer}>{`  대기 삭제  `}</Text>
                    </TouchableOpacity>
                    {/* <TouchableOpacity
                        onPress={payRefund}
                        disabled={item.last_pay_result===1 ? false : true}
                    >
                        <Text style={item.last_pay_result===1 ? GSS.labelContainer : GSS.mainDisableText}>환불하기</Text>
                    </TouchableOpacity> */}
                </View>
            </View>
        );
    }    
    
    const renderItem = ({ item }) => {
        const Item = getItemById(item.item_id);
        return (
            <View style={{flexDirection: 'row', marginBottom:10}}>
                <CheckBox
                    value={selectedItem?.waiting_id===item.waiting_id}
                    onValueChange={() => handleSelectItem(item)}
                />           
                <View style={{flex: 0.3, marginLeft:5}}>
                    <Text style={GSS.mainDefaultText}>{item.waiting_id}</Text>
                </View>
                <View style={{flex: 0.3}}>
                    <TouchableOpacity
                        onPress={() => displayUserInfo(item.user_id)}
                    >
                        <Text style={GSS.mainDefaultTextB}>{item.user_id}</Text>
                    </TouchableOpacity>
                </View>
                <View style={{flex: 0.4}}>
                  <Text style={GSS.mainDefaultText}>{Item?.item_name}</Text>
                </View>
                <View style={{flex: 0.3}}>
                    <Text style={GSS.mainDefaultTextB}>{item.using_type===1 ? '추' : '일'}</Text>
                </View>
                <View style={{flex: 1}}>
                    <Text style={GSS.mainDefaultText}>{formatDate(item.request_at)}</Text>
                </View>                
            </View>
        );
    }; 

    return (
        <View style={[GSS.mainContainer]}>
            <View style={{height:40}}>
            <TopHeader 
                    navigation={navigation}
                    endImageLink={{
                        image : refreshImage,
                        goLink : refreshProc
                    }}
                >대기 정보</TopHeader>
            </View> 
            { selectedItem && (
                <RenderSelectedItem
                item={selectedItem}
            />
            )}            
            <View>
                <Text style={GSS.mainSmallText}>대기ID/유저ID/아이템ID/일반,추가</Text>
            </View>
            <View style={[GSS.mainViewBox, {flex:1,}]}>
                <FlatList
                    data={data}
                    renderItem={renderItem}
                    keyExtractor={item => item.waiting_id}
                    onEndReached={loadMoreData}
                    onEndReachedThreshold={0.5} // 리스트의 50% 지점에서 새 데이터 로드
                    ListFooterComponent={isLoading ? <ActivityIndicator /> : null}
                />
            </View>
        </View>
    );
};

export default WaiterInfo;