import React, {useState, useEffect} from 'react';
import { StyleSheet, Text, View, Image, Platform, BackHandler, ScrollView, TouchableOpacity, ActivityIndicator } from 'react-native';
import { useIsFocused, useFocusEffect} from '@react-navigation/native';
import { useSystemInfo, useAuth, useEnv } from '../AppContext';
import { PrintSystemData, RemoveSystemData, LoadFromSystem } from '../Global';
import GSS from '../GlobalStyleSheet2';
import { useModal } from '../ModalContext';
import CloseHeader from '../CloseHeader';
import nextImage from '../../assets/next.png';
import { HorizontalLine, appVersion } from '../Global';
import NextImage from '../../assets/next.png';
import { GetRow, ExecuteSql, GetRows, GetAccountEmailofDB, GetAccountEmailofDB2, SaveItemToDB } from '../api';
import DropDownPicker from 'react-native-dropdown-picker';
import { generateRandomPassword } from '../EmailAccount';
import * as Clipboard from 'expo-clipboard';

function AccountHome ({navigation, route}) {
  const { systemInfo, setSystemInfo } = useSystemInfo();
  const { isLoggedIn, logout } = useAuth();
  const { ENV, setEnv } = useEnv();
  const isFocused = useIsFocused();
  const [isLogined, setIsLogined] = useState(false); 
  const { confirmModal, showMessage} = useModal();
  const [ currentCreateOTT, setCurrentCreateOTT] = useState(false);
  const [selectedValue, setSelectedValue] = useState();

  useFocusEffect(
    React.useCallback(() => {
        if (Platform.OS === 'web') {
            // 웹 플랫폼인 경우 이 코드 블록을 실행하지 않습니다.
            return;
        }
        const onBackPress = () => {
            navigation.reset({
                index: 0,
                routes: [{ name: 'home' }],
            });            
            return true;
        };
        BackHandler.addEventListener('hardwareBackPress', onBackPress);
        return () => {
            BackHandler.removeEventListener('hardwareBackPress', onBackPress);
        };
    }, [])
);    
  
  useEffect(() => {
    let isMounted = true; // 컴포넌트가 마운트 상태인지 추적합니다.

    // async 함수를 정의하고 즉시 호출합니다.
    (async () => {
      try {
        //const sysData = await LoadFromSystem();
        // 컴포넌트가 여전히 마운트 상태인 경우에만 상태를 설정하거나 로깅을 수행합니다.
        if (isMounted) {
          //console.log(sysData.kakao_id);
          if(ENV.user_id === null) {
            //console.log('로그아웃상태');
            LoginStep();
          } else  {
            const _itmes = ENV.t_item.map(item => ( {
              label : item.display_name,
              value : item.item_id
            }));  
            setOttItems(_itmes);
          }

        }
      } catch (e) {
        // 오류를 적절하게 핸들링합니다. (예: 로깅, 사용자에게 메시지 표시 등)
        console.error(e);
      }
    })();  
    // 컴포넌트 언마운트 시 isMounted를 false로 설정합니다.
    return () => {
      isMounted = false;
    };
  }, [isFocused, ENV.user_id]);
  
  function LoginStep()
  {
      ENV.target_after_login = 'MyInfoHome';
      const newENV = {...ENV};
      newENV.target_after_login = 'MyInfoHome';
      setEnv(newENV);
      if(Platform.OS == 'web')
      {
        navigation.navigate('LoginWeb');
      } else {
        navigation.navigate('Login');
      }
  }

  async function _CreateMyshottAccount (cnt) {
    try {
      const r2 = await ExecuteSql(`insert into t_user set user_name='관리자${cnt}', phone_number='00000000000'`);
      if(r2.success) {
        showMessage(`계정 관리자${cnt}, 아이디[${r2.data.insertId}]가 생성되었습니다`);
      } else {
        showMessage(JSON.stringify(r2.error));
      }
    } catch(error) {
      console.log(error);
    }
  }

  async function CreateMyshottAccount () {
    try {
      const r1 = await GetRow(`select count(*) as cnt from t_user where phone_number='00000000000'`);
      //console.log(r1.data.cnt); 
      const _confirm = {
        afterOK : () => _CreateMyshottAccount(r1.data.cnt),
        body : `현재 ${r1.data.cnt}개의 관리자 계정이 있습니다. 추가 하시겠습니까?`
      };
      confirmModal(_confirm);
    } catch(error) {
        console.log(error);
    } 
  }

  async function CreateOTTAccount () {
    try {
      const ret = await GetRows(`select user_id,user_name from t_user where  phone_number='00000000000'`);
      if( ret.success) {
        const _itmes = ret.data.map(item => ( {
          label : `${item.user_name}(${item.user_id})`,
          value : item.user_id
        }));  
        setUserItems(_itmes);

        setCurrentCreateOTT( prev => !prev);
      } else {
        showMessage('관리자 계정이 없습니다');
      }
    } catch(error) {
        console.log(error);
    } 
  }

  const copyToClipboard = async (text) => {
    await Clipboard.setStringAsync(text);
    //showToast('클립보드에 복사되었습니다.');
  };    

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(null);
  const [ottItmes, setOttItems] = useState();

  const [userOpen, setUserOpen] = useState(false);
  const [userValue, setUserValue] = useState(null);
  const [userItems, setUserItems] = useState();

  const [readyAccount, SetReadyAccount] = useState();
  const [email, setEmail] = useState();
  const [pwd, setPwd] = useState();
  const [myaccountId, setMyaccountId] = useState();
  useEffect(() => {
    (async () => {
      try {
        //console.log(value, userValue);
        if( value && userValue ) {
          const ret = await GetAccountEmailofDB2(userValue, value);
          if( ret.success) {
            setEmail(ret.data.email);
            setMyaccountId(ret.data.myaccount_id);
            setPwd(generateRandomPassword());
            SetReadyAccount(value && userValue);
          }
        }
      } catch (e) {
          // 오류를 적절하게 핸들링합니다. (예: 로깅, 사용자에게 메시지 표시 등)
          console.error(e);
      }
    })();    
  }, [value, userValue]);

  async function createAccount() {
    try {
      const _userItem = {
        user_id: userValue,
        item_id: value,
        maximum_usage: 0,
        remaining_usage: 0,
        maximum_usage2: 0,
        remaining_usage2: 0,            
        nick_name: null,
        account_userid: email,
        account_pwd: pwd,
        account_email_id: null,
        account_email_id2: null,
        free_charge: false,
        check_admin_account: true
      }; 
      if(_userItem.item_id===1) {
        const ret = await GetAccountEmailofDB(userValue, 2);
        if( ret.success ) {
          _userItem.maximum_usage2 = 2;
          _userItem.remaining_usage2 = 0;
          _userItem.account_email_id = ret.data[0].account_id,
          _userItem.account_email_id2 = ret.data[1].account_id
        }
      } else {
        _userItem.maximum_usage = 4;
        _userItem.remaining_usage = 4;     
      }
      const ret2 = await SaveItemToDB(_userItem);
      if( ret2.success) {
        const ret3 = await GetRow('select user_item_id from t_user_item where user_id=? and item_id=? and account_userid=?', 
          [userValue, value, email]);
        if( ret2.success) {
          await ExecuteSql('update t_myaccount set user_item_id=?,pwd=?,is_using=TRUE where myaccount_id=?', [ret3.data.user_item_id, pwd, myaccountId]);
        }
        showMessage('파티가 생성 되었습니다');
      }
  } catch (error) {
      console.log(error);
    }
  }

  function AdminAccount() {
    navigation.navigate('AdminAccount');
  }

    return (
      <View style={GSS.mainContainer}>
        <View style={GSS.mainViewBox}>
          <View style={{padding:5,}}>
              <Text style={[GSS.mainInfoTextB]}>마이샷 계정 관리</Text>
          </View>  
        </View>
        <View style={[GSS.mainViewBox,]}>
            <TouchableOpacity style={{flexDirection:'row', justifyContent:'space-between'}} onPress={AdminAccount}>
              <Text style={GSS.mainInfoText}>마이샷 계정 관리</Text>
              <Image
                  source={NextImage}
                  style={{height:25, width:25}}
              />               
            </TouchableOpacity>
        </View>
        <HorizontalLine/>
        <View style={[GSS.mainViewBox,]}>
            <TouchableOpacity style={{flexDirection:'row', justifyContent:'space-between'}} onPress={CreateMyshottAccount}>
              <Text style={GSS.mainInfoText}>마이샷 계정 생성</Text>
              <Image
                  source={NextImage}
                  style={{height:25, width:25}}
              />               
            </TouchableOpacity>
        </View>
        <View style={[GSS.mainViewBox,]}>
            <TouchableOpacity style={{flexDirection:'row', justifyContent:'space-between'}} onPress={CreateOTTAccount}>
              <Text style={GSS.mainInfoText}>OTT 계정 등록 및 파티 생성</Text>
              <Image
                  source={NextImage}
                  style={{height:25, width:25}}
              />               
            </TouchableOpacity>
        </View>   
        { currentCreateOTT && (
          <React.Fragment>
            <View style={[GSS.mainViewBox,{ zIndex: 1000 }]}>
              <DropDownPicker
                open={userOpen}
                value={userValue}
                items={userItems}
                setOpen={setUserOpen}
                setValue={setUserValue}
                setItems={setUserItems}
                placeholder="관리자를 선택하세요"
              />
          </View>
          {!userOpen && userValue && ( // userValue가 있을 때만 두 번째 드롭다운을 표시
            <View style={[GSS.mainViewBox,{ zIndex: 5000 }]}>
              <DropDownPicker
                open={open}
                value={value}
                items={ottItmes}
                setOpen={setOpen}
                setValue={setValue}
                setItems={setOttItems}
                placeholder="OTT를 선택하세요"
              />
          </View>
          )}
          { Platform.OS==='web' && readyAccount && (
            <View style={[GSS.mainViewBox,]}>
              <View style={{flexDirection:'row'}}>
                <View style={{flex:1}}>
                  <View style={[{flex:1, borderWidth:1, borderRadius:5}]}>

                    <View style={{flexDirection:'row', flex:1,}}>
                      <View style={{width:40}}>
                        <Text style={GSS.mainDefaultText}>계정</Text>
                      </View>
                      <View style={{flex:2, }}>
                        <Text style={GSS.mainDefaultText}>{email}</Text>
                      </View>
                      <TouchableOpacity style={{width:60}} onPress={() => copyToClipboard(email)}>
                        <Text style={GSS.mainInfoText}>복사</Text>
                      </TouchableOpacity>
                    </View>

                    <View style={{flexDirection:'row', flex:1, marginBottom:5}}>
                      <View style={{width:35}}>
                        <Text style={GSS.mainDefaultText}>비번</Text>
                      </View>
                      <View style={{flex:1, }}>
                        <Text style={GSS.mainDefaultText}>{pwd}</Text>
                      </View>
                      <TouchableOpacity style={{width:60}}  onPress={() => copyToClipboard(pwd)}>
                        <Text style={GSS.mainInfoText}>복사</Text>
                      </TouchableOpacity>
                    </View>               

                  </View>
                </View>
                <View style={{marginLeft:5, alignItems:'center', justifyContent:'center', flex:0.2, borderWidth:0.5, borderRadius:10,
                    backgroundColor: '#017cf7'}}>
                  <TouchableOpacity 
                    onPress={createAccount}
                  >
                    <Text style={[GSS.mainInfoTextB, {color:'#ffffff'}]}>생성</Text>
                  </TouchableOpacity>
                </View>
              </View>          
            </View>
          )}
          { Platform.OS!=='web' && readyAccount && (
            <View style={[GSS.mainViewBox, {flexDirection:'row', }]}>
            <View style={{flex:1}}>
                <View style={[SS.textContainer]}>
                  <View style={{flexDirection:'row'}}>
                          <View style={{width:35}}>
                              <Text style={GSS.mainDefaultText}>계정</Text>
                          </View>
                          <View style={{flex:1, }}>
                              <Text style={GSS.mainInfoTextB}>{email}</Text>
                          </View>
                          <TouchableOpacity style={{flex:0.2}}  onPress={() => copyToClipboard(email)}>
                              <Text style={GSS.mainInfoText}>복사</Text>
                          </TouchableOpacity>
                      </View>
                      <View style={{flexDirection:'row', marginBottom:5}}>
                          <View style={{width:35}}>
                              <Text style={GSS.mainDefaultText}>비번</Text>
                          </View>
                          <View style={{flex:1, }}>
                              <Text style={GSS.mainInfoTextB}>{pwd}</Text>
                          </View>
                          <TouchableOpacity style={{flex:0.2}}   onPress={() => copyToClipboard(pwd)}>
                              <Text style={GSS.mainInfoText}>복사</Text>
                          </TouchableOpacity>
                      </View>               
            </View>
            </View>
              <View style={{marginLeft:5, alignItems:'center', justifyContent:'center', flex:0.2, borderWidth:0.5, borderRadius:10,
                    backgroundColor: '#017cf7'}}>
                  <TouchableOpacity 
                    onPress={createAccount}
                  >
                    <Text style={[GSS.mainInfoTextB, {color:'#ffffff'}]}>생성</Text>
                  </TouchableOpacity>
                </View>
        </View>
          )}          
          </React.Fragment>
        )}
        <View style={[GSS.emptyViewBox, {flex:1}]}>
        </View>

      </View> 
  );
};

const SS = StyleSheet.create({
  container: {
    // 컨테이너를 flex 컨테이너로 설정합니다.
    flex: 1,
    justifyContent: 'flex-end', // 자식 요소들을 컨테이너의 오른쪽으로 정렬합니다.
    alignItems: 'center', // 자식 요소들을 컨테이너의 중앙으로 정렬합니다(수직 방향).
    borderWidth:1,
    borderColor : '#8F8C83',
    margin:5,
    borderRadius:10,
    marginBottom : 15
  },
  confirmView: {
      //flex: 
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems : 'center',
      marginBottom : 10
  },
  textContainer: {
      alignItems:'center',
      padding:10, 
      borderWidth:1,
      borderRadius:5,
      borderColor: '#8F8C83'
  }
});

export default AccountHome;