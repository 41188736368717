import React, {useState, useEffect} from 'react';
import { Text, View, TouchableOpacity , Image, ScrollView, ActivityIndicator, FlatList, Platform } from 'react-native';
import { useAuth, useEnv, GetOTTByName} from '../AppContext';
import GSS from '../GlobalStyleSheet2'
import NextImage from '../../assets/next.png';
import { GetRow, GetRows, DeleteUserItemToDB, UpdateUserMessageToDB, GetPatryNicknams} from '../api';
import TopHeader from '../TopHeader';
import { useFocusEffect } from '@react-navigation/native';
import refreshImage from '../../assets/refresh.png';
import CheckBox from 'expo-checkbox';
import { useModal } from '../ModalContext';
import { formatDate, HorizontalLine} from '../Global';
import CheckNotifyError from './CheckNotifyError';
import Message from '../../assets/message.png';
import AlertImage from '../../assets/alert.png';
import nextImage from '../../assets/next.png';
import nextBImage from '../../assets/nextB.png';
import prevImage from '../../assets/prev.png';
import prevBImage from '../../assets/prevB.png';
import ProgressButton, {ProgressButtonOrg} from '../ProgressButton';

function AdminAccount ({navigation, route}) {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMoreData, setHasMoreData] = useState(true);
    const { isLoggedIn } = useAuth();
    const [ refreshOK, SetRefreshOK] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const { getItemById } = useEnv();
    const { showMessage, openModal, closeModal} = useModal();
    let msgViewerModalID;

    useFocusEffect(
        React.useCallback(() => {
            if(isLoggedIn!==true) {
                navigation.reset({
                    index: 0,
                    routes: [{ name: 'home' }],
                });                
            }
        }, [isLoggedIn]) // 여기서는 의존성 배열을 비워둡니다.
    );      

    const fetchData = async () => {
        if (isLoading || !hasMoreData) return;
        setIsLoading(true);
        try {
            const itemsPerPage = 25;
            const offset = (page - 1) * itemsPerPage;
            const sql = 
`SELECT TA.myaccount_id, TA.email, TA.pwd, TA.user_id, TA.item_id, TA.is_using,
TU.create_at, TU.account_userid, TU.account_pwd,
TU.maximum_usage, TU.remaining_usage, TU.maximum_usage2, TU.remaining_usage2, 
TU.nick_name, TU.account_email_id, TU.account_email_id2 , TU.user_item_id 
FROM t_myaccount as TA
LEFT JOIN t_user_item as TU ON TA.user_item_id = TU.user_item_id
WHERE TA.is_using = TRUE
ORDER BY TA.create_at DESC
LIMIT ${itemsPerPage} OFFSET ${offset};`;
            const ret = await GetRows(sql);
            //console.log(ret);
            if (ret.success) {
                let newData = ret.data;
                for(const item of newData) {
                    const item_error = await GetRow('SELECT count(*) AS cnt FROM t_user_item_error WHERE user_item_id=? AND check_completed=FALSE', [item.user_item_id]);
                    item.error_cnt = item_error.data.cnt;
                    const messages = await GetRows('SELECT * FROM t_user_message WHERE user_item_id = ? AND checked_at is NULL', [item.user_item_id]);
                    item.messages = messages.data;
                    const party = await GetPatryNicknams(item.user_item_id);
                    let _nicks = '';
                    for( const nick of party.data) {
                      //console.log(nick, typeof nick);
                      if( nick) _nicks += ` [${nick}]`;
                    }
                    item.nicks = _nicks;
                }
                if (newData.length > 0) {
                    setData(prevData => [...prevData, ...newData]);
                    setPage(prevPage => prevPage + 1);
                } else {
                    setHasMoreData(false);
                }
            }
        } catch (e) {
            console.error(e);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const loadMoreData = () => {
        fetchData();
    };

    useEffect(() => {
        if(refreshOK) {
            fetchData();
            SetRefreshOK(false);
        }
    }, [refreshOK]);     

    const resetAndReloadData = () => {
        setData([]); // 데이터 배열을 비웁니다.
        setPage(1); // 페이지 번호를 초기화합니다.
        setHasMoreData(true); // 더 많은 데이터가 있다고 가정합니다.
        SetRefreshOK(true);
        setSelectedItem(false);
    };   

    const handleSelectItem = async (item) => {
        try {
            const userItem = {
                user_item : item,
                emailAcouunt1 : null,
                emailAcouunt2 : null
            }
            if( item.account_email_id ) {
                const ret = await GetRow('SELECT * FROM t_email_account WHERE account_id=?', [item.account_email_id]);
                if( ret.success) {
                    userItem.emailAcouunt1 = ret.data;
                }
            }
            if( item.account_email_id2 ) {
                const ret2 = await GetRow('SELECT * FROM t_email_account WHERE account_id=?', [item.account_email_id2]);
                if(ret2.success) {
                    userItem.emailAcouunt2 = ret2.data;
                }
            }
            if(selectedItem===null || selectedItem.user_item.myaccount_id !== item.myaccount_id) {
                setSelectedItem(userItem);
            } else {
                setSelectedItem(null);
            }
        } catch(error) {
            console.log(error);
        }
    };    

    const deleteItem = async () => {
        setIsLoading(true);
        try {
            const ret = await DeleteUserItemToDB(selectedItem?.user_item.user_item_id);
            if( ret.success ) {
                resetAndReloadData();
            }
        } catch(error) {
            showMessage(error.message);
        }
        setIsLoading(false);
    }    

    const sendEmailForInvite = (user_item, emailAccount) => {
        if( Platform.OS === 'web') {
            showMessage('앱 버젼에서 진행해 주세요');
            return;
        }
        const userAccounts = [
            {
                account_id : emailAccount.account_id,
                account_status : emailAccount.last_status,
                account_email : emailAccount.email,
            },
        ];
        //console.log(user_item);
        const item = getItemById(user_item.item_id);
        navigation.navigate('NetflixMatchedProc', { user_item, item, userAccounts });
    }

    const CheckAccountStatus = ({user_item, emailAccount}) => {
        //console.log(emailAccount);
        if( !emailAccount) return null;
        if( emailAccount.last_status === 0) {
            return (
                <TouchableOpacity onPress={ () => sendEmailForInvite(user_item, emailAccount)}>
                    <Text style={GSS.mainInfoTextB}>  초대메일발송</Text>
                </TouchableOpacity> 
            );
        } else if( emailAccount.last_status === 1) {
            return(
                <Text style={GSS.mainInfoText}>  매칭대기</Text>
            );
        } else if (emailAccount.last_status === 2) {
            return (<Text style={GSS.mainInfoText}>  파티매칭</Text>);

        } else if (emailAccount.last_status === 3) {
            return (<Text style={GSS.mainInfoText}>  초대수락</Text>);
        } else if (emailAccount.last_status === 4) {
            return (<Text style={GSS.mainInfoText}>  파티해지</Text>);
        }
   }

   const myshottEmail = (email) => {
        // if( Platform.OS == 'web') {
        //     showMessage('쉽고 빠르게 넷플릭스 추가회원 비밀번호 변경을 하시려면 안드로이드,iOS 마이샷 앱버젼을 이용하세요')
        // } else {
            
        // }
        navigation.navigate('MyshottEmail', { accountEmail : email});    
    }    

    const [modalID, setModalID] = useState();
    const checkNotifyError = (item) => {
        const id = openModal(
            <CheckNotifyError
              modalID={modalID}
              closeModal={closeModal}
              user_item= {item}
            /> ,
            0.4,    // heightPercentage, 필요한 경우 다른 값으로 설정
            0.6,   // widthPercentage
            'slide', // animation, 필요한 경우 다른 값으로 설정
            {},      // style, 필요한 경우 스타일 객체로 설정
            //true     // centered, 모달을 중앙에 배치하려면 true로 설정                
          );
          setModalID(id);
    }     

    const MessageViewer = ({_messages}) => {
        const [messages, setMessages] = useState(_messages); // 초기 메시지 배열
        const [currentIndex, setCurrentIndex] = useState(0); // 현재 메시지 인덱스
        const [allMessagesRead, setAllMessagesRead] = useState(false);
      
        const goToPrevMessage = () => {
          setCurrentIndex(currentIndex - 1);
        };
      
        const goToNextMessage = () => {
          setCurrentIndex(currentIndex + 1);
        };

        // 마지막 메시지에 도달했는지 확인
        useEffect(() => {
            if (currentIndex === messages.length - 1) {
                setAllMessagesRead(true);
            }
        }, [currentIndex]);

        const confirmMessage = async () => {
            try {
                const message_ids = messages.map(msg => msg.user_message_id);
                //console.log(message_ids);
                await UpdateUserMessageToDB(message_ids);
                closeModal(msgViewerModalID);
            } catch (error) {
                console.error("MessageViewer error:", error);
                // 필요한 추가적인 에러 처리 로직
            }
        };
      
        return (
            <View style={{ flex: 1, padding: 5, margin:5}}>
                <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                    <View>
                        <Text style={GSS.messageTitleText}>'<Text style={GSS.textEmphasis}>{messages[currentIndex]?.from_nick_name}</Text>'님의 쪽지</Text>
                    </View>
                    <View style={{ flexDirection: 'row', padding: 10 }}>
                        <TouchableOpacity
                            disabled={currentIndex === 0}
                            onPress={goToPrevMessage}
                        >
                            <Image
                                source={currentIndex === 0 ? prevImage : prevBImage}
                                style={{height:30, width:30}}
                            />                           
                        </TouchableOpacity>
                        <TouchableOpacity
                            disabled={currentIndex === messages.length - 1}
                            onPress={goToNextMessage}
                        >
                            <Image
                                source={currentIndex === messages.length - 1 ? nextImage : nextBImage}
                                style={{height:30, width:30}}
                            />
                        </TouchableOpacity>
                    </View>
                </View>
                <ScrollView style = {{flex:5}}>
                    <Text style={GSS.messageText}>{messages[currentIndex]?.user_message}</Text>
                </ScrollView>
                <View style={{alignItems:'center', marginTop:15}}>
                    <ProgressButton
                        title ='확인'
                        style = {allMessagesRead ? {backgroundColor:'#1e1f31'} : {backgroundColor:'#cbcbe3'}}
                        textStyle = {{color:'#FFFFFF'}}
                        disabled={!allMessagesRead}
                        onPress={confirmMessage}                
                    />
                </View>
            </View>
        );
    };

    const ShowMessageAlarm = ({messages}) => {
        //console.log(messages);
        const handleGoShowMessage = (messages) => {
            //console.log('messages :', messages);
            msgViewerModalID = openModal(
                <MessageViewer
                    _messages = {messages}
                /> ,
                0.4,    // heightPercentage, 필요한 경우 다른 값으로 설정
                0.85,   // widthPercentage
                'slide', // animation, 필요한 경우 다른 값으로 설정
                {},      // style, 필요한 경우 스타일 객체로 설정
                true     // centered, 모달을 중앙에 배치하려면 true로 설정                
            );        

        };

        return (
            <View>
                <TouchableOpacity
                            onPress={() => handleGoShowMessage(messages)}
                >                  
                    <Image
                        source = {Message}
                        style = {{height: 25,width: 25}}
                    />
                </TouchableOpacity>
            </View>
        );
    }    

    function RenderSelectedItem ({userItem}) {
        const Item = getItemById(userItem.user_item.item_id);
        return (
            <View style={[GSS.mainViewBox, {borderWidth:1}]}>
                <View style={{marginLeft:10, marginTop:10}}>
                    <TouchableOpacity 
                        onPress={() => myshottEmail(userItem.user_item.account_userid)}
                        style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
                    >
                        <Text style={[GSS.mainDefaultText]}>{userItem.user_item.account_userid}</Text>
                        <Text style={GSS.mainDefaultText}>메일내용보기</Text>
                    </TouchableOpacity>    
                </View>
                <HorizontalLine
                    thickness={0.5}
                />              
                <View style={{flexDirection: 'row', }}>
                    <View style={{flex: 0.3}}>
                        <Text style={GSS.mainDefaultText}>{userItem.user_item.user_item_id}</Text>
                    </View>
                    <View style={{flex: 0.3}}>
                        <Text style={GSS.mainDefaultTextB}>{userItem.user_item.user_id}</Text>
                    </View>
                    <View style={{flex: 0.4}}>
                        <Text style={GSS.mainDefaultText}>{Item?.item_name}</Text>
                    </View>
                    <View style={{flex: 0.7}}>
                        <Text style={GSS.mainDefaultText}>{formatDate(userItem.user_item.create_at)}</Text>
                    </View>
                    {/* <View style={{flex: 1}}>
                        <Text style={GSS.mainDefaultText}>{formatDate(item.request_at)}</Text>
                    </View>                 */}
                </View>
                <HorizontalLine
                    thickness={0.5}
                />
                    <View style={{flexDirection: 'row', }}>
                        <View style={{flex: 1}}>
                            <Text style={GSS.mainDefaultTextB}>{userItem.user_item.nick_name}</Text>
                        </View>
                        <View style={{flex: 0.3, alignItems:'center',justifyContent:'center'}}>
                            <Text style={GSS.mainDefaultTextB}>{userItem.user_item.remaining_usage}({userItem.user_item.maximum_usage})</Text>
                       </View>                       
                    </View>
                <HorizontalLine
                    thickness={0.5}
                />                
                { userItem.user_item.maximum_usage2 && userItem.user_item.maximum_usage2 > 0 && (
                    <React.Fragment>
                        <View style={{flexDirection: 'row', }}>
                            <View style={{flex:1}}>
                                <View style={{flexDirection: 'row', }}>
                                    <View style={{flexDirection: 'row', }}>
                                        <Text style={GSS.mainDefaultText}>{userItem.emailAcouunt1?.email}</Text>
                                        <CheckAccountStatus
                                            user_item = {userItem.user_item}
                                            emailAccount = {userItem.emailAcouunt1}
                                        />
                                    </View>
                                </View>
                                <View style={{flexDirection: 'row', }}>
                                    <View style={{flexDirection: 'row', }}>
                                        <Text style={GSS.mainDefaultText}>{userItem.emailAcouunt2?.email}</Text>
                                        <CheckAccountStatus
                                            user_item = {userItem.user_item}
                                            emailAccount = {userItem.emailAcouunt2}
                                        />                            
                                    </View>
                                </View>                        
                            </View>
                            <View style={{flex: 0.3, alignItems:'center',justifyContent:'center'}}>
                                <Text style={GSS.mainDefaultTextB}>{userItem.user_item.remaining_usage2}({userItem.user_item.maximum_usage2})</Text>
                            </View>
                        </View>
                        <HorizontalLine
                            thickness={0.5}
                        />
                    </React.Fragment>
                )}
                {isLoading && (
                <View style={GSS.activityIndicatorContainer}>
                    <ActivityIndicator size='large' color="#0000ff" />
                </View>
                )}         
                <View style={{flexDirection: 'row', justifyContent:'space-evenly', alignItems:'center' }}>
                    <TouchableOpacity
                        onPress={deleteItem}
                    >
                        <Text style={GSS.labelContainer}>{`  파티 삭제  `}</Text>
                    </TouchableOpacity>
                    {/* <TouchableOpacity
                        onPress={payRefund}
                        disabled={item.last_pay_result===1 ? false : true}
                    >
                        <Text style={item.last_pay_result===1 ? GSS.labelContainer : GSS.mainDisableText}>환불하기</Text>
                    </TouchableOpacity> */}
                </View>
            </View>
        );
    }    

    function renderItem({ item }) {
        const Item = getItemById(item.item_id);
        const OTT = GetOTTByName(Item.item_name);        
        // console.log(item.error_cnt);
        //console.log(item.messages.length);
        return (
            <View style={{flexDirection: 'row', marginBottom:5}}>
                <View style={{flexDirection:'row'}}></View>
                    <View style={{alignItems:'center', justifyContent:'center'}}>
                        <CheckBox
                            disabled={item.user_item_id===null ? true : false}
                            value={selectedItem?.user_item?.myaccount_id===item.myaccount_id}
                            onValueChange={() => handleSelectItem(item)}
                        />                   
                    </View>
                    <View style={{flex:1}}>
                        <View style={{flexDirection:'row', flex:1}}>
                            <View style={{flex: 0.25, marginLeft:5, flexDirection:'row'}}>
                                <Text style={GSS.mainDefaultTextB}>{item.user_id}</Text>
                                {
                                    item.messages && item.messages.length>0 && 
                                        <ShowMessageAlarm
                                            messages = {item.messages}
                                        />
                                }                                
                            </View>
                            <View style={{flex: 1}}>
                                <Text style={GSS.mainDefaultTextB}>{item.email}</Text>
                            </View>
                            <View style={{flex: 0.5, flexDirection:'row'}}>
                                <Text style={GSS.mainDefaultText}>{OTT.name}</Text>
                                { item.error_cnt > 0 && (
                                    <React.Fragment>
                                        <TouchableOpacity
                                            onPress={ () => checkNotifyError(item)}
                                        >
                                            <Image
                                                source={AlertImage}
                                                style={{height:25, width:25, marginLeft:5}}
                                            />
                                        </TouchableOpacity>
                                    </React.Fragment>
                                )}                                
                            </View>
                        </View>
                        <View style={{flexDirection:'row', flex:1}}>
                            { item.user_item_id === null ? (
                                <View style={{alignItems:'center', marginLeft:5}}>
                                    <Text style={GSS.emphasisText}>파티 X</Text>
                                </View>
                            ) : (
                                <React.Fragment>
                                    <View style={{flex: 0.25, marginLeft:5}}>
                                        <Text style={GSS.mainDefaultText}></Text>
                                    </View>
                                    <View style={{flex: 1}}>
                                        <Text style={GSS.mainDefaultTextB}>{item.pwd}</Text>
                                    </View>
                                    <View style={{flex: 0.5}}>
                                        <Text style={GSS.mainDefaultTextB}>
                                            {item.maximum_usage-item.remaining_usage}({item.maximum_usage})
                                            {item.maximum_usage2 > 0 && ` ${item.maximum_usage2-item.remaining_usage2}(${item.maximum_usage2})`}
                                        </Text>
                                    </View>
                                </React.Fragment>                                
                            )}
                        </View>
                    </View>
            </View>
        );
    }

    return (
        <View style={[GSS.mainContainer]}>
            <View style={{height:40}}>
                <TopHeader 
                    navigation={navigation}
                    endImageLink={{
                        image : refreshImage,
                        goLink : resetAndReloadData
                    }}
                >관리자 계정 관리</TopHeader>
            </View>  
            { selectedItem && (
                <RenderSelectedItem
                userItem={selectedItem}
            />
            )}               
            <View style={[GSS.mainViewBox, {flex:1,}]}>
                <FlatList
                    data={data}
                    renderItem={renderItem}
                    keyExtractor={item => item.myaccount_id}
                    onEndReached={loadMoreData}
                    onEndReachedThreshold={0.5} // 리스트의 50% 지점에서 새 데이터 로드
                    ListFooterComponent={isLoading ? <ActivityIndicator /> : null}
                />
            </View>
        </View>
    );
};

export default AdminAccount;