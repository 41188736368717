import React, {useState, useEffect} from 'react';
import { Text, View, TouchableOpacity , Image, Dimensions, ActivityIndicator, FlatList, Platform } from 'react-native';
import { useAuth, useEnv, GetOTTByName} from '../AppContext';
import GSS from '../GlobalStyleSheet2'
import NextImage from '../../assets/next.png';
import { GetRow, GetRows, SendEmailForInvite} from '../api';
import TopHeader from '../TopHeader';
import { formatDate, HorizontalLine} from '../Global';
import { useFocusEffect } from '@react-navigation/native';
import { DeleteUserItemToDB } from '../api';
import refreshImage from '../../assets/refresh.png';
import CheckBox from 'expo-checkbox';
import { useModal } from '../ModalContext';
import CopyImage from '../../assets/copy.png';
import * as Clipboard from 'expo-clipboard';

function UserItemInfo ({navigation, route}) {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMoreData, setHasMoreData] = useState(true);
    const { getItemById } = useEnv();
    const { isLoggedIn } = useAuth();
    const [selectedItem, setSelectedItem] = useState(null);
    const { showModalMessage } = useModal()    
    const [ refreshOK, SetRefreshOK] = useState(false);
    const target_user_id = route.params?.target_user_id ?? null;
    const target_user_item_id = route.params?.target_user_item_id ?? null;

    useFocusEffect(
        React.useCallback(() => {
            if(isLoggedIn!==true) {
                navigation.reset({
                    index: 0,
                    routes: [{ name: 'home' }],
                });                
            }
        }, [isLoggedIn]) // 여기서는 의존성 배열을 비워둡니다.
    );       

    const fetchData = async () => {
        if (isLoading || !hasMoreData) return;
        setIsLoading(true);
        try {
            const itemsPerPage = 25;
            const offset = (page - 1) * itemsPerPage;
            let sql;
            // if( target_user_id ) {
            //     sql = `SELECT * FROM t_user_item WHERE user_id=${target_user_id} ORDER BY create_at DESC LIMIT ${itemsPerPage} OFFSET ${offset}`;
            // } else {
            //     sql = `SELECT * FROM t_user_item ORDER BY create_at DESC LIMIT ${itemsPerPage} OFFSET ${offset}`;
            // }
            if( target_user_item_id ) {
                sql = `
                    SELECT tui.*, 
                        COALESCE(SUM(CASE WHEN tui2.user_item_id IS NOT NULL THEN 1 ELSE 0 END), 0) AS used_item_count
                    FROM t_user_item tui
                    LEFT JOIN t_used_item tui2 ON tui.user_item_id = tui2.user_item_id
                    WHERE tui.user_item_id = ${target_user_item_id}
                    GROUP BY tui.user_item_id
                    ORDER BY tui.create_at DESC
                    LIMIT ${itemsPerPage} OFFSET ${offset}
                `;
            } else if( target_user_id ) {
                sql = `
                    SELECT tui.*, 
                        COALESCE(SUM(CASE WHEN tui2.user_item_id IS NOT NULL THEN 1 ELSE 0 END), 0) AS used_item_count
                    FROM t_user_item tui
                    LEFT JOIN t_used_item tui2 ON tui.user_item_id = tui2.user_item_id
                    WHERE tui.user_id = ${target_user_id}
                    GROUP BY tui.user_item_id
                    ORDER BY tui.create_at DESC
                    LIMIT ${itemsPerPage} OFFSET ${offset}
                `;
            } else {
                sql = `
                    SELECT tui.*, COALESCE(COUNT(tui2.user_item_id), 0) AS used_item_count
                    FROM t_user_item tui
                    LEFT JOIN t_used_item tui2 ON tui.user_item_id = tui2.user_item_id
                    GROUP BY tui.user_item_id
                    ORDER BY tui.create_at DESC
                    LIMIT ${itemsPerPage} OFFSET ${offset}
                `;
            }           
            const ret = await GetRows(sql);
            if (ret.success) {
                const newData = ret.data;
                if (newData.length > 0) {
                    setData(prevData => [...prevData, ...newData]);
                    setPage(prevPage => prevPage + 1);
                } else {
                    setHasMoreData(false);
                }
            }
        } catch (e) {
            console.error(e);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if(refreshOK) {
            fetchData();
            SetRefreshOK(false);
        }
    }, [refreshOK]);     

    useEffect(() => {
        // 컴포넌트가 마운트될 때 실행할 조건 로직
        if (target_user_item_id) {
            console.log(data)
            //handleSelectItem(targetItem);
        }
    }, [target_user_item_id]); // 의존성 배열에 target_user_item_id를 포함   

    const loadMoreData = () => {
        fetchData();
    };

    const refreshProc = () => {
        setData([]); // 데이터 배열을 비웁니다.
        setPage(1); // 페이지 번호를 초기화합니다.
        setHasMoreData(true); // 더 많은 데이터가 있다고 가정합니다.
        setSelectedItem(false);
        //await fetchData(); // 데이터를 다시 불러옵니다.
        SetRefreshOK(true);
    };      

    const handleSelectItem = async (item) => {
        try {
            const userItem = {
                user_item : item,
                emailAcouunt1 : null,
                emailAcouunt2 : null
            }
            if( item.account_email_id ) {
                const ret = await GetRow('SELECT * FROM t_email_account WHERE account_id=?', [item.account_email_id]);
                if( ret.success) {
                    userItem.emailAcouunt1 = ret.data;
                }
            }
            if( item.account_email_id2 ) {
                const ret2 = await GetRow('SELECT * FROM t_email_account WHERE account_id=?', [item.account_email_id2]);
                if(ret2.success) {
                    userItem.emailAcouunt2 = ret2.data;
                }
            }
            setSelectedItem(selectedItem?.user_item === item ? null : userItem);
        } catch(error) {
            console.log(error);
        }
    };    

    const deleteItem = async () => {
        setIsLoading(true);
        try {
            const ret = await DeleteUserItemToDB(selectedItem?.user_item.user_item_id);
            if( ret.success ) {
                refreshProc();
            }
        } catch(e) {
            const _message = {
                body : error.message
            }
            showModalMessage(_message);
        }
        setIsLoading(false);
    }

    const sendEmailForInvite = async (email) => {
        const _message = {
            body : ''
        }
        try {
            setIsLoading(true);
            const ret = await SendEmailForInvite(email);
            setIsLoading(false);
            if(ret.success) {
                _message.body = `메일이 발송되었습니다(${ret.data})`
            } else {
                _message.body = JSON.stringify(ret.error);
            }
        } catch (error) {
            _message.body = JSON.stringify(error.message);
        }
        showModalMessage(_message);
    };    

    const CheckAccountStatus = ({userItem, emailAccount}) => {
        //console.log(emailAccount);
        if( !emailAccount) return null;
        if( emailAccount.last_status === 0) {
            return (
                <TouchableOpacity onPress={ () => sendEmailForInvite(emailAccount.email)}>
                    <Text style={GSS.mainInfoTextB}>  초대메일발송</Text>
                </TouchableOpacity> 
            );
        } else if( emailAccount.last_status === 1) {
            return(
                <Text style={GSS.mainInfoText}>  매칭대기</Text>
            );
        } else if (emailAccount.last_status === 2) {
            return (<Text style={GSS.mainInfoText}>  파티매칭</Text>);

        } else if (emailAccount.last_status === 3) {
            return (<Text style={GSS.mainInfoText}>  초대수락</Text>);
        } else if (emailAccount.last_status === 4) {
            return (<Text style={GSS.mainInfoText}>  파티해지</Text>);
        }
   }

    const copyToClipboard = async (text) => {
        await Clipboard.setStringAsync(text);
        if(Platform.OS === 'web') {
            const _message = {
                body : `[${text}]를 클립보드에 복사하였습니다`
            }
            showModalMessage(_message);
        } else {
            showToast('클립보드에 복사되었습니다.');
        }
    };  

    const displayUsedItem = (user_item_id) => {
        navigation.navigate('UsedItemInfo', { target_user_item_id: user_item_id });
    }

    const displayUserInfo = (user_id) => {
        navigation.navigate('UserInfo', { target_user_id: user_id });
    }     

    const goOTTSite = async (OTT) => {
        window.open(OTT.homeUrl, '_blank');
    }   

    function RenderSelectedItem ({userItem}) {
        if (!selectedItem?.user_item)  return null;
        const Item = getItemById(userItem.user_item.item_id);
        const OTT = GetOTTByName(Item.item_name);
        return (
            <View style={[GSS.mainViewBox, {borderWidth:1}]}>
                <View style={{flexDirection: 'row', }}>
                    <View style={{flex: 0.3}}>
                        <Text style={GSS.mainDefaultText}>{userItem.user_item.user_item_id}</Text>
                    </View>
                    <View style={{flex: 0.3}}>
                        <Text style={GSS.mainDefaultTextB}>{userItem.user_item.user_id}</Text>
                    </View>
                    <View style={{flex: 0.4}}>
                        <Text style={GSS.mainDefaultText}>{Item?.item_name}</Text>
                    </View>
                    <View style={{flex: 0.7}}>
                        <Text style={GSS.mainDefaultText}>{formatDate(userItem.user_item.create_at)}</Text>
                    </View>
                    {/* <View style={{flex: 1}}>
                        <Text style={GSS.mainDefaultText}>{formatDate(item.request_at)}</Text>
                    </View>                 */}
                </View>
                <View style={{flexDirection:'row'}}>
                    <View style={{flex:1, flexDirection:'row'}}>
                        <Text style={GSS.mainDefaultText}>ID:[<Text style={GSS.mainDefaultTextB}>{userItem.user_item.account_userid}</Text>]</Text>
                        <TouchableOpacity style={{marginLeft:5}} onPress={() => copyToClipboard(userItem.user_item.account_userid)}>
                            <Image
                                source={CopyImage}
                                style={{width:20, height:20, marginLeft:5}}
                            />
                        </TouchableOpacity>
                    </View>
                    <View style={{flex:1, flexDirection:'row'}}>
                    <Text style={GSS.mainDefaultText}>ID:[<Text style={GSS.mainDefaultTextB}>{userItem.user_item.account_pwd}</Text>]</Text>
                        <TouchableOpacity style={{marginLeft:5}} onPress={() => copyToClipboard(userItem.user_item.account_pwd)}>
                            <Image
                                source={CopyImage}
                                style={{width:20, height:20, marginLeft:5}}
                            />
                        </TouchableOpacity>                        
                    </View>                    
                </View>
                <HorizontalLine
                    thickness={0.5}
                />
                    <View style={{flexDirection: 'row', }}>
                        <View style={{flex: 1}}>
                            <Text style={GSS.mainDefaultTextB}>{userItem.user_item.nick_name}</Text>
                        </View>
                        <View style={{flex: 0.3, alignItems:'center',justifyContent:'center'}}>
                            <Text style={GSS.mainDefaultTextB}>{userItem.user_item.remaining_usage}({userItem.user_item.maximum_usage})</Text>
                       </View>                       
                    </View>
                <HorizontalLine
                    thickness={0.5}
                />                
                { userItem.user_item.maximum_usage2 && userItem.user_item.maximum_usage2 > 0 && (
                    <React.Fragment>
                        <View style={{flexDirection: 'row', }}>
                            <View style={{flex:1}}>
                                <View style={{flexDirection: 'row', }}>
                                    <View style={{flexDirection: 'row', }}>
                                        <Text style={GSS.mainDefaultText}>{userItem.emailAcouunt1?.email}</Text>
                                        <CheckAccountStatus
                                            userItem = {userItem}
                                            emailAccount = {userItem.emailAcouunt1}
                                        />
                                    </View>
                                </View>
                                <View style={{flexDirection: 'row', }}>
                                    <View style={{flexDirection: 'row', }}>
                                        <Text style={GSS.mainDefaultText}>{userItem.emailAcouunt2?.email}</Text>
                                        <CheckAccountStatus
                                            userItem = {userItem}
                                            emailAccount = {userItem.emailAcouunt2}
                                        />                            
                                    </View>
                                </View>                        
                            </View>
                            <View style={{flex: 0.3, alignItems:'center',justifyContent:'center'}}>
                                <Text style={GSS.mainDefaultTextB}>{userItem.user_item.remaining_usage2}({userItem.user_item.maximum_usage2})</Text>
                            </View>
                        </View>
                        <HorizontalLine
                            thickness={0.5}
                        />
                    </React.Fragment>
                )}
                {isLoading && (
                <View style={GSS.activityIndicatorContainer}>
                    <ActivityIndicator size='large' color="#0000ff" />
                </View>
                )}         
                <View style={{flexDirection: 'row', justifyContent:'space-evenly', alignItems:'center' }}>
                    <TouchableOpacity
                        onPress={() => goOTTSite(OTT)}
                    >
                        <Text style={GSS.labelContainer}>{`  OTT 바로가기  `}</Text>
                    </TouchableOpacity>               
                    { userItem.user_item.used_item_count > 0 && (
                        <TouchableOpacity
                            onPress={() => displayUsedItem(userItem.user_item.user_item_id)}
                        >
                            <Text style={GSS.labelContainer}>{`  파티원보기  `}</Text>
                        </TouchableOpacity>  
                    )}
                    <TouchableOpacity
                        onPress={deleteItem}
                    >
                        <Text style={GSS.labelContainer}>{`  파티 삭제  `}</Text>
                    </TouchableOpacity>
                </View>
            </View>
        );
    }    

    const renderItem = ({ item }) => {
        const Item = getItemById(item.item_id);
        const OTT = GetOTTByName(Item.item_name);
        return (
            <React.Fragment>
                <View style={{flexDirection: 'row', marginBottom:10}}>
                    <CheckBox
                        value={selectedItem?.user_item?.user_item_id===item.user_item_id || target_user_item_id}
                        onValueChange={() => handleSelectItem(item)}
                    />           
                    <View style={{flex: 0.25, marginLeft:5}}>
                        <View style={{flexDirection: 'row'}}>
                            <Text style={[GSS.mainDefaultText, {marginRight:5}]}>{item.user_item_id}</Text>
                            { item.free_charge===1 && (
                                <Text style={GSS.emphasisText}>free</Text>
                            )}
                        </View>
                    </View>
                    <View style={{flex: 0.25}}>
                        <TouchableOpacity
                            onPress={() => displayUserInfo(item.user_id)}
                        >
                            <Text style={GSS.mainDefaultTextB}>{item.user_id}</Text>
                        </TouchableOpacity>                     
                    </View>
                    <View style={{flex: 0.4}}>
                    <Text style={GSS.mainDefaultText}>{Item.item_name}</Text>
                    </View>
                    <View style={{flex: 0.25}}>
                        <Text style={GSS.mainDefaultText}>{item.remaining_usage}({item.maximum_usage})</Text>
                    </View>
                    <View style={{flex: 0.25}}>
                        <Text style={GSS.mainDefaultText}>{item?.remaining_usage2}{item?.maximum_usage2 && `(${item.maximum_usage2})`}</Text>
                    </View>
                    <View style={{flex: 1}}>
                        <Text style={GSS.mainDefaultText}>{formatDate(item.create_at)}</Text>
                    </View>                              
                </View>
                <View style={{flexDirection: 'row', marginBottom:5}}>
                </View>
            </React.Fragment>
        );
    }; 

    return (
        <View style={[GSS.mainContainer]}>
            <View style={{height:40}}>
                <TopHeader 
                    navigation={navigation}
                    endImageLink={{
                        image : refreshImage,
                        goLink : refreshProc
                    }}
                >파티 정보</TopHeader>
            </View> 
            { selectedItem && (
                <RenderSelectedItem
                userItem={selectedItem}
            />
            )}             
            <View><Text style={GSS.mainSmallText}>ID/사용자아이디/아이템/일횟/초횟</Text></View>
            <View style={[GSS.mainViewBox, {flex:1,}]}>
                
                <FlatList
                    data={data}
                    renderItem={renderItem}
                    keyExtractor={item => item.user_item_id}
                    onEndReached={loadMoreData}
                    onEndReachedThreshold={0.5} // 리스트의 50% 지점에서 새 데이터 로드
                    ListFooterComponent={isLoading ? <ActivityIndicator /> : null}
                />
            </View>
        </View>
    );
};

export default UserItemInfo;