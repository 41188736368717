import React, { useEffect, useState, useRef  } from 'react';
import { Text, View,  TouchableOpacity, StyleSheet, Image, Dimensions } from 'react-native';
import GSS from '../GlobalStyleSheet2';
import TopHeader from '../TopHeader';
import { generateRandomPassword } from '../EmailAccount.js';
import { GetEmail, SetEmailPassword } from '../api.js';
import { useModal } from '../ModalContext';
import * as Clipboard from 'expo-clipboard';
import { Button } from 'react-native-web';
import ConfirmImage from '../ConfirmImage';

export default function CheckEmailContent({navigation, route}) {
    const { emailAccount } = route.params;
    const [ htmlEmail, setHtmlEmail] = useState();
    const [password, setPassword] = useState();
    const { confirmModal, showMessage, showModalMessage } = useModal();
    const [ checkOpenEmail, setCheckOpenEmail] = useState(false);
    const [ completed, setCompleted] = useState(false);

    useEffect(() => {
        try {
            (async () => {
                const ret = await GetEmail(emailAccount.email_id);
                if( ret.success) {
                    //console.log(ret.data);
                    setHtmlEmail(ret.data);
                }
                refreshPassword();                  
            })();         
        } catch (error) {
            console.error(error.message);
        }
    }, []);   

    const refreshPassword = () => {
        setPassword(generateRandomPassword());
    }    

    const openHtmlInNewWindow = (htmlContent) => {
        setCheckOpenEmail(true);
        const newWindow = window.open('', '_blank');
        newWindow.document.open();
        newWindow.document.write(htmlContent);
        newWindow.document.close();
    };

    const copyToClipboard = async (text, isMessage=true) => {
        await Clipboard.setStringAsync(text);
        if( isMessage ) {
            showMessage('클립보드에 복사되었습니다.');
        }
    };

    const _goBack = () => {
        navigation.goBack();
    }
    
    const _completeProc = async () => {
        try {
            const ret = await SetEmailPassword(emailAccount.account_id, password);
            if(ret.success) {
                const _message = {
                    afterOK : _goBack,
                    body : `수고하셨습니다. 진행이 완료되었습니다. 오른쪽 아래 완료버트를 클릭하여 주세요`
                }
                showModalMessage( _message);        
            } else {
                showMessage('비밀번호 저장에 실패하였습니다')
            }
        } catch(error) {
            console.log(error);
        }
    }

    const completeProc = () => {
        const _confirem = {
            afterOK : _completeProc,
            body: `[${emailAccount.email}] 계정에 대한 비번으로 [${password}]를 설정하셨나요?`
        }
        confirmModal(_confirem);
    }    

    const windowWidth = Dimensions.get('window').width;
    return (
        <View style={[GSS.mainContainer]}>
            <View style={{height:40}}>
                <TopHeader 
                    navigation={navigation}
                >초대메일 수락</TopHeader>
            </View> 
            <View style={[GSS.mainViewBox, {flex:1}]}>
                <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                    <Text style={[GSS.mainInfoText, {width:40}]}>계정</Text>
                    <Text style={[GSS.mainInfoText, {flex:1}]}>{emailAccount?.email}</Text>
                    <TouchableOpacity onPress={() => copyToClipboard(emailAccount?.email)}>
                        <Text style={[GSS.mainDefaultText, {marginRight:10}]}>복사</Text>
                    </TouchableOpacity>
                </View>
                <View style={{flexDirection:'row', justifyContent:'space-between', marginTop:10}}>
                    <Text style={[GSS.mainInfoText, {width:40}]}>비번</Text>
                    <Text style={[GSS.mainInfoTextB, {flex:1}]}>{password}</Text>
                    <TouchableOpacity onPress={() => copyToClipboard(password)}>
                        <Text style={[GSS.mainDefaultText, {marginRight:10}]}>복사</Text>
                    </TouchableOpacity>
                </View>     
                {/* <View style={{marginTop:25}}>
                    <Button title='넷플릭스 초대 수락메일 보기' onPress={() => openHtmlInNewWindow(htmlEmail?.body)}/>
                </View>   
                <View style={{marginTop:25}}>
                    <Button title='완 료' onPress={completeProc}/>
                </View>                          */}

                <View style={[GSS.mainViewBox, {marginTop: 25}]}>
                    <View style={SS.confirmView}>
                        <Text style={[GSS.mainInfoText, {margin:3, width:windowWidth*0.8, }]}>넷플릭스 초대메일을 확인합니다</Text>
                        <ConfirmImage
                            isActive={checkOpenEmail}
                            onPress={() => openHtmlInNewWindow(htmlEmail?.body)}
                        />                        
                    </View>
                </View>
                { checkOpenEmail===true && (
                    <View style={GSS.mainViewBox}>
                        <View>
                            <Text style={GSS.mainDefaultText}>초대메일 내용에서 '시작하기'를 클릭..'벰버십 활성화'를 릭... 자동생성되어 복사된 비밀번호를 지정하고 하고 마지막까지 진행 완료후 완료를 확인해 주세요</Text>
                        </View>
                        <View style={SS.confirmView}>
                            <Text style={[GSS.mainInfoText, {margin:3, width:windowWidth*0.8, }]}>완료 되었나요?</Text>
                            <ConfirmImage
                                isActive={completed}
                                onPress={completeProc}
                            />                        
                        </View>
                    </View>                   
                )}
            </View>
        </View>        
    );
}

const SS = StyleSheet.create({
    confirmView: {
        //flex: 
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems : 'center',
    },     
});