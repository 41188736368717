import Axios from 'axios';
import { routerServer } from './Global.js';

const fetchDataFromDB = async (url) => {
  try {
    const response = await Axios.get(url);
    if (response.status === 200) {
      return { success: true, data: response.data };
    } else {
      return { success: false, error: response.data };
    }
  } catch (error) {
    // 네트워크 오류나 서버에서 오류 응답을 보낸 경우
    if (error.response) {
      // 서버가 응답한 경우 (예: HTTP 상태 코드 400, 500)
      return { success: false, error: error.response.data };
    } else if (error.request) {
      // 요청이 이루어졌으나 응답을 받지 못한 경우
      return { success: false, error: "No response received from server" };
    } else {
      // 요청을 만드는 중 오류 발생
      return { success: false, error: error.toString() };
    }
  }
};

const saveDataToDB = async (url, saveData) => {
  try {
    const response = await Axios.post(url, saveData);
    if (response.status === 201) {
      return { success: true, data: response.data };
    } else {
      return { success: false, error: response.data };
    }
  } catch (error) {
    // 네트워크 오류나 서버에서 오류 응답을 보낸 경우
    if (error.response) {
      // 서버가 응답한 경우 (예: HTTP 상태 코드 400, 500)
      return { success: false, error: error.response.data };
    } else if (error.request) {
      // 요청이 이루어졌으나 응답을 받지 못한 경우
      return { success: false, error: "No response received from server" };
    } else {
      // 요청을 만드는 중 오류 발생
      return { success: false, error: error.toString() };
    }
  }
};

export const GetRow = async (sql, params=null) => {
  const postData = {
    sql : sql,
    params : params
  };
  const url = `${routerServer}/eva/get_row`;
  //console.log(url);
  return await saveDataToDB(url, postData);      
};

export const GetRows = async (sql, params=null) => {
  const postData = {
    sql : sql,
    params : params
  };
  const url = `${routerServer}/eva/get_rows`;
  //console.log(url);
  return await saveDataToDB(url, postData);      
};

export const ExecuteSql = async (sql, params=null) => {
  const postData = {
    sql : sql,
    params : params
  };
  const url = `${routerServer}/eva/execute_sql`;
  //console.log(url);
  return await saveDataToDB(url, postData);      
};

export const GetRefundInfo = async (used_item_id) => {
  const url = `${routerServer}/eva/get_refund_info/${used_item_id}`;
  //console.log(url);
  return await fetchDataFromDB(url);
};

export const RefundPay = async (refundInfo) => {
  //const url = `${routerServer}/eva/pay_refund`;
  const url = `https://myshott.co.kr/eva/pay_refund`;
  console.log(url);
  return await saveDataToDB(url, refundInfo);      
};

export const DeleteUsedItemToDB = async (used_item_id) => {
  const url = `${routerServer}/db/delete_useditem/${used_item_id}`;
  //console.log(url);
  return await fetchDataFromDB(url);
};

export const SendEmailForInvite = async (email) => {
  const url = `${routerServer}/eva/send_invite_email/${email}`;
  //console.log(url);
  return await fetchDataFromDB(url);
};

export const DeleteUserItemToDB = async (user_item_id) => {
  const url = `${routerServer}/db/delete_useritem/${user_item_id}`;
  //console.log(url);
  return await fetchDataFromDB(url);
};

export const CancelWaitingFromDB = async (waiting_id) => {
  const url = `${routerServer}/db/cancel_waiting/${waiting_id}`;
  //console.log(url);
  return await fetchDataFromDB(url);
};

// 이메일을 생성 또는 만들어진 이메일 요청 갯수만큼 얻어온다
export const GetAccountEmailofDB = async (user_id, request_count, user_item_id=null) => {
  let url;
  if( user_item_id) 
    url = `${routerServer}/db/create_email_account/${user_id}/${request_count}/${user_item_id}`;
  else 
    url = `${routerServer}/db/create_email_account/${user_id}/${request_count}/-1`;
  //console.log(url);
  return await fetchDataFromDB(url);
};

export const GetAccountEmailofDB2 = async (user_id, item_id, user_item_id=null) => {
  const postData = {
    user_id : user_id,
    item_id : item_id,
    user_item_id : user_item_id
  };  
  const url = `${routerServer}/db/create_email_account2`;
  //console.log(url);
  return await saveDataToDB(url, postData);        
};

export const SaveItemToDB = async (curItem) => {
  const url = `${routerServer}/db/save_useritem`;
  //console.log(url);
  return await saveDataToDB(url, curItem);        
};

// 마이샷 이메일 내용 얻기
export const GetEmailContentofDB = async (email, count) => {
  let url = `${routerServer}/postfix/get_email_content/${email}/${count}`;;
  //console.log(url);
  return await fetchDataFromDB(url);
};

// 파티장 관리 오류 목록 얻기
export const GetErrorType = async () => {
  let url = `${routerServer}/db/get_error_type`;;
  //console.log(url);
  return await fetchDataFromDB(url);
};

// 파티장 오류 내용 신고
export const saveNotifyError = async (notifyContent) => {
  const postData = {
    user_item_id : notifyContent.user_item_id,
    used_item_id : notifyContent.used_item_id,
    user_id : notifyContent.user_id, 
    error_type : notifyContent.error_type,
    error_desc : notifyContent.error_des
  };
  const url = `${routerServer}/db/notify_error`;
  //console.log(url);
  return await saveDataToDB(url, postData);      
};

// 파티장 오류 내용 얻기
export const GetNotifyError = async (user_item_id) => {
  let url = `${routerServer}/db/get_notify_error/${user_item_id}`;;
  //console.log(url);
  return await fetchDataFromDB(url);
};

// 파티장 오류 내용 얻기
export const SetNotifyError = async (error_id) => {
  let url = `${routerServer}/db/set_notify_error/${error_id}`;;
  //console.log(url);
  return await fetchDataFromDB(url);
};

export const UpdateUserMessageToDB = async (message_ids) => {
  const postData = {
    message_ids : message_ids
  };
  const url = `${routerServer}/db/update_user_message`;
  //console.log(url);
  return await saveDataToDB(url, postData);      
};

// 현재 파티에서 사용중인 닉네임을 확인한다
export const GetPatryNicknams = async (user_item_id) => {
  let url = `${routerServer}/db/get_party_nicks/${user_item_id}`;;
  //console.log(url);
  return await fetchDataFromDB(url);
};

// t_email 의 레코드값을 읽는다
export const GetEmail = async (email_id) => {
  const url = `${routerServer}/postfix/get_email/${email_id}`;
  //console.log(url);
  return await fetchDataFromDB(url);
};

// t_email 의 레코드값을 읽는다, 현 t_email_account의 email_id가 업데이트 된 경우만을...
export const GetNewEmail = async (email, email_id) => {
  const url = `${routerServer}/postfix/get_new_email/${email}/${email_id}`;
  //console.log(url);
  return await fetchDataFromDB(url);
};

// t_email 의 마지막 email_id와 t_email_account의 email_id를 일치시킨다
export const SetSyncEmailID = async (account_id) => {
  const url = `${routerServer}/postfix/set_sync_email/${account_id}`;
  //console.log(url);
  return await fetchDataFromDB(url);
};

export const SetEmailPassword = async (account_id, password) => {
  const postData = {
    account_id : account_id,
    pwd : password
  };
  const url = `${routerServer}/db/set_account_password`;
  //console.log(url);
  return await saveDataToDB(url, postData);      
};