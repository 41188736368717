import React, {useState, useEffect} from 'react';
import { Text, View, TouchableOpacity , Image, Dimensions, ActivityIndicator, FlatList, StyleSheet} from 'react-native';
import { useAuth, useEnv, GetOTTByName} from '../AppContext';
import GSS from '../GlobalStyleSheet2'
import NextImage from '../../assets/next.png';
import { GetRow, GetRows} from '../api';
import TopHeader from '../TopHeader';
import { formatDate, HorizontalLine } from '../Global';
import { useFocusEffect } from '@react-navigation/native';
import refreshImage from '../../assets/refresh.png';
import CheckBox from 'expo-checkbox';
import { DeleteUsedItemToDB } from '../api';
import { useModal } from '../ModalContext';
import CopyImage from '../../assets/copy.png';
import * as Clipboard from 'expo-clipboard';
import LinkImage from '../../assets/share.png';

function UsedItemInfo ({navigation, route}) {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMoreData, setHasMoreData] = useState(true);
    const { getItemById } = useEnv();
    const { isLoggedIn } = useAuth();
    const [selectedItem, setSelectedItem] = useState(null);
    const { showModalMessage } = useModal()
    const [ refreshOK, SetRefreshOK] = useState(false);
    const [ emailAccount, setEmailAccount] = useState();
    const target_user_id = route.params?.target_user_id ?? null;
    const target_user_item_id = route.params?.target_user_item_id ?? null;

    useFocusEffect(
        React.useCallback(() => {
            if(isLoggedIn!==true) {
                navigation.reset({
                    index: 0,
                    routes: [{ name: 'home' }],
                });                
            }
        }, [isLoggedIn]) // 여기서는 의존성 배열을 비워둡니다.
    );      

    const fetchData = async () => {
        if (isLoading || !hasMoreData) return;
        setIsLoading(true);
        try {
            const itemsPerPage = 25;
            const offset = (page - 1) * itemsPerPage;
            let sql;
            if( target_user_id ) {
                sql = `SELECT * FROM t_used_item WHERE user_id=${target_user_id} ORDER BY start_use DESC LIMIT ${itemsPerPage} OFFSET ${offset}`;
            } else if( target_user_item_id) {
                sql = `SELECT * FROM t_used_item WHERE user_item_id=${target_user_item_id} ORDER BY start_use DESC LIMIT ${itemsPerPage} OFFSET ${offset}`;
            } else {
                sql = `SELECT * FROM t_used_item ORDER BY start_use DESC LIMIT ${itemsPerPage} OFFSET ${offset}`;
            }            
            const ret = await GetRows(sql);
            if (ret.success) {
                const newData = ret.data;
                if (newData.length > 0) {
                    setData(prevData => [...prevData, ...newData]);
                    setPage(prevPage => prevPage + 1);
                } else {
                    setHasMoreData(false);
                }
            }
        } catch (e) {
            console.error(e);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if(refreshOK) {
            fetchData();
            SetRefreshOK(false);
        }
    }, [refreshOK]);    

    const loadMoreData = () => {
        fetchData();
    };

    const refreshProc = () => {
        setData([]); // 데이터 배열을 비웁니다.
        setPage(1); // 페이지 번호를 초기화합니다.
        setHasMoreData(true); // 더 많은 데이터가 있다고 가정합니다.
        setSelectedItem(false);
        //await fetchData(); // 데이터를 다시 불러옵니다.
        SetRefreshOK(true);
    };    

    const goOTTSite = async (OTT) => {
        window.open(OTT.homeUrl, '_blank');
    }

    const handleSelectItem = async (item) => {
        try {
            if( item.account_email_id ) {
                const ret = await GetRow('SELECT * FROM t_email_account WHERE account_id=?', [item.account_email_id]);
                if( ret.success) {
                    setEmailAccount( ret.data);
                }
            }
            setSelectedItem(selectedItem === item ? null : item);
        } catch (error) {
            console.log(error);
        }
    };    

    const deleteItem = async () => {
        setIsLoading(true);
        try {
            const ret = await DeleteUsedItemToDB(selectedItem.used_item_id);
            if( ret.success ) {
                refreshProc();
            }
        } catch(e) {
            const _message = {
                body : error.message
            }
            showModalMessage(_message);
        }
        setIsLoading(false);
    }

    const CheckAccountStatus = ({emailAccount}) => {
        //console.log(emailAccount);
        if( !emailAccount) return null;
        if( emailAccount.last_status === 0) {
            return (
                <TouchableOpacity onPress={ () => sendEmailForInvite(emailAccount.email)}>
                    <Text style={GSS.mainInfoTextB}>  초대메일발송</Text>
                </TouchableOpacity> 
            );
        } else if( emailAccount.last_status === 1) {
            return(
                <Text style={GSS.mainInfoText}>  매칭대기</Text>
            );
        } else if (emailAccount.last_status === 2) {
            return (<Text style={GSS.mainInfoText}>  파티매칭</Text>);

        } else if (emailAccount.last_status === 3) {
            return (<Text style={GSS.mainInfoText}>  초대수락</Text>);
        } else if (emailAccount.last_status === 4) {
            return (<Text style={GSS.mainInfoText}>  파티해지</Text>);
        }
    }

    const displayUserItem = (user_item_id) => {
        navigation.navigate('UserItemInfo', { target_user_item_id: user_item_id });
    }  

    const displayUserInfo = (user_id) => {
        navigation.navigate('UserInfo', { target_user_id: user_id });
    }       

    const copyToClipboard = async (text) => {
        await Clipboard.setStringAsync(text);
        if(Platform.OS === 'web') {
            const _message = {
                body : `[${text}]를 클립보드에 복사하였습니다`
            }
            showModalMessage(_message);
        } else {
            showToast('클립보드에 복사되었습니다.');
        }
    }; 
    
    const checkEmailContent = () => {
        navigation.navigate('CheckEmailContent', { emailAccount });
    }   

    const verify19 = () => {
        navigation.navigate('verify19', { emailAccount });
    }    

    function RenderSelectedItem ({item}) {
        if(selectedItem === null) return null;
        const Item = getItemById(item.item_id);
        const OTT = GetOTTByName(Item.item_name);
        return (
            <View style={[GSS.mainViewBox, {borderWidth:1}]}>
                <View style={{flexDirection: 'row', }}>
                    <View style={{flex: 0.3}}>
                        <Text style={GSS.mainDefaultText}>{item.used_item_id}</Text>
                    </View>
                    <View style={{flex: 0.3}}>
                        <Text style={GSS.mainDefaultTextB}>{item.user_id}</Text>
                    </View>
                    <View style={{flex: 0.4}}>
                        <Text style={GSS.mainDefaultText}>{Item?.item_name}</Text>
                    </View>
                    <View style={{flex: 0.3}}>
                        <Text style={GSS.mainDefaultText}>{item.user_item_id}</Text>
                    </View>                                         
                    <View style={{flex: 0.4}}>
                        <Text style={GSS.mainDefaultTextB}>{item.nick_name}</Text>
                    </View>                                                             
                    <View style={{flex: 1}}>
                        <Text style={GSS.mainDefaultText}>{formatDate(item.start_use)}</Text>
                    </View>
                    {/* <View style={{flex: 1}}>
                        <Text style={GSS.mainDefaultText}>{formatDate(item.request_at)}</Text>
                    </View>                 */}
                </View>
                <HorizontalLine
                    thickness={0.5}
                />
                { item.using_type === 1 && (
                    <React.Fragment>
                        <View style={{flexDirection: 'row', }}>
                            <View style={{flex: 1, flexDirection:'row'}}>
                                <Text style={GSS.mainDefaultText}>{emailAccount.email}</Text>
                                <TouchableOpacity style={{marginLeft:5}} onPress={() => copyToClipboard(emailAccount.email)}>
                                    <Image
                                        source={CopyImage}
                                        style={{width:20, height:20, marginLeft:5}}
                                    />
                                </TouchableOpacity>              
                            </View>
                            <View style={{flex: 1, flexDirection:'row'}}>
                                <Text style={GSS.mainDefaultText}>{emailAccount.pwd}</Text>
                                <TouchableOpacity style={{marginLeft:5}} onPress={() => copyToClipboard(emailAccount.pwd)}>
                                    <Image
                                        source={CopyImage}
                                        style={{width:20, height:20, marginLeft:5}}
                                    />
                                </TouchableOpacity>              
                            </View>                           
                            <View style={{flex: 0.5}}>
                                <CheckAccountStatus
                                    emailAccount = {emailAccount}
                                />
                           </View>
                        </View>
                        <HorizontalLine
                            thickness={0.5}
                        />
                    </React.Fragment>
                )}
                <View style={{flexDirection: 'row', justifyContent:'space-evenly', alignItems:'center' }}>
                    { emailAccount?.last_status === 2 && (
                        <TouchableOpacity
                            onPress={checkEmailContent}
                        >
                            <Text style={GSS.labelContainer}>{`초대메일 수락`}</Text>
                        </TouchableOpacity> 
                    )}
                    { emailAccount?.last_status === 3 && (
                        <TouchableOpacity
                            onPress={verify19}
                        >
                            <Text style={GSS.labelContainer}>{`성인 인증`}</Text>
                        </TouchableOpacity> 
                    )}                    
                    <TouchableOpacity
                        onPress={() => goOTTSite(OTT)}
                    >
                        <Text style={GSS.labelContainer}>{`  OTT 바로가기  `}</Text>
                    </TouchableOpacity>                
                    <TouchableOpacity
                        onPress={() => displayUserItem(item.user_item_id)}
                    >
                        <Text style={GSS.labelContainer}>{`  파티 보기  `}</Text>
                    </TouchableOpacity>               
                    <TouchableOpacity
                        onPress={deleteItem}
                    >
                        <Text style={GSS.labelContainer}>{`  파티원 삭제  `}</Text>
                    </TouchableOpacity>
                </View>
            </View>
        );
    }    
    
    const renderItem = ({ item }) => {
        const Item = getItemById(item.item_id);
        const OTT = GetOTTByName(Item.item_name);        
        return (
            <View style={[
                { flexDirection: 'row', marginBottom: 10},
                selectedItem?.used_item_id===item.used_item_id ? SS.selected : null
              ]}>
                <CheckBox
                    value={selectedItem?.used_item_id===item.used_item_id}
                    onValueChange={() => handleSelectItem(item)}
                />           
                <View style={{flex: 0.3, marginLeft:5}}>
                    <View style={{flexDirection: 'row'}}>
                        <Text style={[GSS.mainDefaultText, {marginRight:5}]}>{item.used_item_id}</Text>
                        { item.free_charge===1 && (
                            <Text style={GSS.emphasisText}>free</Text>
                        )}
                    </View>
                </View>
                <View style={{flex: 0.3}}>
                    <TouchableOpacity
                        onPress={() => displayUserInfo(item.user_id)}
                    >
                        <Text style={GSS.mainDefaultTextB}>{item.user_id}</Text>
                    </TouchableOpacity> 
                </View>
                <View style={{flex: 0.4}}>
                  <Text style={GSS.mainDefaultText}>{Item.item_name}</Text>
                </View>
                <View style={{flex: 0.3}}>
                    <TouchableOpacity
                        onPress={() => displayUserItem(item.user_item_id)}
                    >
                        <Text style={GSS.mainDefaultText}>{item.user_item_id}</Text>
                    </TouchableOpacity>
                </View>
                <View style={{flex: 0.3}}>
                    <Text style={item.using_type===0 ? GSS.mainDefaultText : GSS.textEmphasis}>
                        {item.using_type===0 ? '일' : '초'}
                    </Text>
                </View>                
                <View style={{flex: 1}}>
                    <Text style={GSS.mainDefaultText}>{formatDate(item.start_use)}</Text>
                </View>
            </View>
        );
    }; 

    return (
        <View style={[GSS.mainContainer]}>
            <View style={{height:40}}>
                <TopHeader 
                    navigation={navigation}
                    endImageLink={{
                        image : refreshImage,
                        goLink : refreshProc
                    }}
                >파티원 정보</TopHeader>
            </View> 
            { selectedItem && (
                <RenderSelectedItem
                item={selectedItem}
            />
            )}                
            <View><Text style={GSS.mainSmallText}>ID/사용자아이디/아이템/파티아이디/일회,초회</Text></View>
            <View style={[GSS.mainViewBox, {flex:1,}]}>
                <FlatList
                    data={data}
                    renderItem={renderItem}
                    keyExtractor={item => item.used_item_id}
                    onEndReached={loadMoreData}
                    onEndReachedThreshold={0.5} // 리스트의 50% 지점에서 새 데이터 로드
                    ListFooterComponent={isLoading ? <ActivityIndicator /> : null}
                />
            </View>
        </View>
    );
};

const SS = StyleSheet.create({
    selected : {
        borderBottomWidth : 0.5
    }
});
export default UsedItemInfo;